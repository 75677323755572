* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

@font-face {
  font-family: "satoshi";
  src: url("./assets/fonts/Satoshi-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "satoshi";
  src: url("./assets/fonts/Satoshi-Regular.woff") format("woff");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "satoshi";
  src: url("./assets/fonts/Satoshi-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "satoshi";
  src: url("./assets/fonts/Satoshi-Bold.woff") format("woff");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "clashDisplay";
  src: url("./assets/fonts/ClashDisplay-Regular.woff") format("woff");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "clashDisplay";
  src: url("./assets/fonts/ClashDisplay-Semibold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "caveat";
  src: url("./assets/fonts/Caveat-Bold.woff") format("woff");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "jakarta-regular";
  src: url("./assets/fonts/PlusJakartaSans-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "railway-regular";
  src: url("./assets/fonts/Raleway-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}




.lenis.lenis-smooth {
  scroll-behavior: auto !important;
}
html.lenis {
  height: auto;
}
html,
body {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-size: 14px;
  /* scroll-behavior: smooth; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 400;
  font-family: "railway-regular", sans-serif;
}

/*---------------- theme------------ */

.container {
  max-width: 100%;
  padding-left: 20px;
  padding-right: 20px;
  margin-left: auto;
  margin-right: auto;
}
@media (min-width: 992px) {
  .container {
    max-width: 90%;
  }
}
@media (min-width: 1540px) {
  .container {
    max-width: 1440px;
  }
}
