.drawer {
  display: none;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  z-index: 9999;
  justify-content: flex-end;
}
.drawer.show {
  display: flex;
}
.drawer .drawer-backdrop {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(10px);
}
.drawer .drawer-content {
  position: relative;
  z-index: 10;
  width: 500px;
  height: 100%;
  background-color: #ffffff;
  overflow-y: auto;
}
.drawer-content .drawer-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 2rem;
}
.drawer-content .drawer-title {
  color: #000;
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  margin-bottom: 0;
}
.drawer-content .close {
  cursor: pointer;
}
.drawer-content .drawer-body .modal-info {
  padding: 1rem 2rem;
  background: linear-gradient(
    120deg,
    rgba(255, 255, 255, 0.12) 28.09%,
    rgba(255, 74, 0, 0.12) 129.42%
  );
}
.drawer-content .drawer-body .modal-info .img-container {
  margin-bottom: 2rem;
  height: 260px;
  width: 208px;
  border-radius: 1rem;
  overflow: hidden;
}
.drawer-body .modal-info .body-title {
  color: #000;
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  margin-bottom: 0rem;
}
.drawer-content .drawer-body .modal-info .body-subtitle {
  color: rgba(0, 0, 0, 0.52);
  font-size: 18px;
  font-weight: 700;
  line-height: 28px;
  margin-bottom: 0;
}
.drawer-content .drawer-body .info-sec {
  padding: 1rem 2rem;
  background: #ffffff;
}

.drawer-content .drawer-body .info-sec .body-text {
  color: rgba(0, 0, 0, 0.52);
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
  margin-bottom: 1.5rem;
}
.drawer-content .drawer-body .info-sec .mail {
  display: flex;
  align-items: center;
  gap: 10px;
  color: #000;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  margin-bottom: 2rem;
}
.drawer-content .drawer-body .info-sec .social-title {
  color: #000;
  font-size: 20px;
  font-weight: 500;
  line-height: 28px;
  margin-bottom: 0.75rem;
}
.drawer-content .drawer-body .info-sec .social {
  text-decoration: none;
}
