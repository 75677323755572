.landing-banner .app-qr-code {
  display: none;
}
@media (min-width: 992px) {
  .landing-banner .app-qr-code {
    position: fixed;
    right: 50px;
    bottom: 40px;
    z-index: 99;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 214px;
    height: 113px;
    padding: 16px;
    border-radius: 20px;
    background: rgba(0, 0, 0, 0.48);
    backdrop-filter: blur(12px);
  }
}
.landing-banner .app-qr-code img {
  width: 70px;
}
.landing-banner .download-text {
  color: #fff;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  margin-left: 15px;
  margin-bottom: 0px;
}
@media (min-width: 992px) {
  .landing-banner .app-qr-code {
    width: 249px;
    height: 123px;
    padding: 20px;
  }
  .landing-banner .app-qr-code img {
    width: 90px;
  }
}

/* -------------- banner------------ */

.landing-banner {
  position: relative;
  width: 100%;
  height: 100%;
  margin-top: 70px;
  padding-top: 0px;
  background-color: #ffffff;
}
.landing-banner .banner-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
}
@media (min-width: 992px) {
  .landing-banner .banner-wrapper {
    flex-direction: row;
  }
}
.landing-banner .banner-wrapper .content-main {
  width: 100%;
}
@media (min-width: 992px) {
  .landing-banner .banner-wrapper .content-main {
    width: 60%;
  }
}
.landing-banner .banner-wrapper .content-box {
  margin-bottom: 20px;
  padding-top: 40px;
  padding-left: 20px;
  padding-right: 20px;
}
@media (min-width: 992px) {
  .landing-banner .banner-wrapper .content-box {
    max-width: 90%;
    margin-left: auto;
    margin-bottom: 20px;
    padding-top: 90px;
    font-family: "clash-display", sans-serif;
  }
}
.landing-banner .banner-wrapper .content-box .banner-title {
  color: #000;
  font-family: 'clashDisplay';
  font-size: 36px;
  font-weight: 700;
  line-height: 46px;
  margin-bottom: 20px;
}
.landing-banner .banner-wrapper .content-box .banner-title span {
  color: #ff4a00;
}
@media (min-width: 992px) {
  .landing-banner .banner-wrapper .content-box .banner-title {
    font-size: 48px;
    font-family: 'clashDisplay';
    line-height: 60px;
  }
}
.landing-banner .banner-wrapper .content-box .banner-text {
  color: #000000;
  font-family: "railway-regular", sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  margin-bottom: 30px;
}
@media (min-width: 150px) {
  .landing-banner .banner-wrapper .content-box .banner-text {
    font-size: 20px;
    line-height: 28px;
  }
}

.landing-banner .banner-wrapper .content-box .btn-box {
  display: flex;
  flex-direction: column;
  gap: 15px;
  align-items: start;
  justify-content: flex-start;
  margin-bottom: 25px;
}

@media (min-width: 992px) {
  .landing-banner .banner-wrapper .content-box .btn-box {
    flex-direction: row;
    gap: 20px;
  }
}

.landing-banner .banner-wrapper .content-box .btn-box .assessment-btn {
  background: #ff4a00;
  color: white;
}

.landing-banner .banner-wrapper .content-box .btn-box .chat-btn {
  background: #2d3748;
  color: white;
}

.landing-banner .banner-wrapper .content-box .btn-box .business-btn {
  background: white;
  color: #ff4a00;
  border: 1px solid #ff4a00;
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 12px 24px;
  font-size: 14px;
  line-height: 1.2;
  white-space: nowrap;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  transition: all 0.3s ease;
}

.landing-banner .banner-wrapper .content-box .btn-box .business-btn:hover {
  background: rgba(255, 74, 0, 0.05);
}

@media (max-width: 992px) {
  .landing-banner .banner-wrapper .content-box .btn-box .business-btn {
    font-size: 13px;
    padding: 10px 20px;
  }
}

.landing-banner .banner-wrapper .content-box .btn-box .download-app,
.landing-banner .banner-wrapper .content-box .store-box .download-app {
  display: flex;
  align-items: center;
  border: 0;
  outline: 0;
  background: none;
  color: #ff4a00;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  margin-left: 0px;
  margin-top: 20px;
  text-decoration: none;
}
@media (min-width: 768px) {
  .landing-banner .banner-wrapper .content-box .store-box .download-app {
    font-size: 16px;
  }
}
@media (min-width: 992px) {
  .landing-banner .banner-wrapper .content-box .btn-box .download-app {
    margin-top: 0;
    margin-left: 30px;
    font-size: 16px;
  }
}
.landing-banner .banner-wrapper .content-box .btn-box .download-app img {
  width: 20px;
  margin-left: 10px;
}
.landing-banner .banner-wrapper .content-box .store-box {
  display: flex;
}
.landing-banner .banner-wrapper .content-box .store-box {
  display: flex;
  align-items: center;
}
.landing-banner .banner-wrapper .content-box .store-text {
  color: rgba(0, 0, 0, 0.52);
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px; /* 166.667% */
  letter-spacing: 1px;
  text-transform: uppercase;
  margin-bottom: 0px;
}

.landing-banner .banner-wrapper .content-box .store-list {
  display: flex;
  list-style: none;
  margin-bottom: 0px;
  padding-left: 15px;
}
@media (min-width: 768px) {
  .landing-banner .banner-wrapper .content-box .store-list {
    padding-left: 30px;
  }
}
.landing-banner .banner-wrapper .content-box .store-item {
  margin-right: 15px;
}
.landing-banner .banner-wrapper .content-box .store-item img {
  width: 20px;
}
.landing-banner .banner-wrapper .sliding-mainCards {
  position: relative;
  overflow: hidden;
}
@media (max-width: 992px) {
  .landing-banner .banner-wrapper .content-main .sliding-mainCards {
    display: none;
  }
}
@media (min-width: 992px) {
  .landing-banner .banner-wrapper .image-container .sliding-mainCards {
    display: none;
  }
}
.landing-banner .banner-wrapper .sliding-mainCards .right-overlay {
  display: none;
  position: absolute;
  top: 0px;
  right: 0px;
  z-index: 99;
  width: 150px;
  height: 100%;
  background: rgb(255, 255, 255);
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 1) 100%
  );
}
@media (min-width: 992px) {
  .landing-banner .banner-wrapper .sliding-mainCards .right-overlay {
    display: block;
  }
}

.landing-banner .banner-wrapper .sliding-mainCards .slider-card {
  padding: 10px;
}
.landing-banner .banner-wrapper .sliding-mainCards .slider-card .card-body {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  text-align: left;
  border: 1px solid #e6e4dd;
  border-radius: 20px;
  background-color: #ffffff;
  cursor: pointer;
}
@media (min-width: 992px) {
  .landing-banner .banner-wrapper .sliding-mainCards .slider-card .card-body {
    min-height: 110px;
  }
}

.landing-banner .banner-wrapper .sliding-mainCards .slider-card .slider-title {
  color: #00000085;
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
  margin-bottom: 0px;
}
@media (min-width: 1300px) {
  .landing-banner
    .banner-wrapper
    .sliding-mainCards
    .slider-card
    .slider-title {
    font-size: 16px;
  }
}
.landing-banner
  .banner-wrapper
  .sliding-mainCards
  .slider-card
  .card-body:hover
  .slider-title {
  text-decoration: underline;
}
.landing-banner .banner-wrapper .sliding-mainCards .slider-card img {
  width: 20px;
}
.landing-banner .banner-wrapper .image-container {
  display: block;
  width: 100%;
  text-align: center;
  padding-top: 10px;
}
@media (min-width: 992px) {
  .landing-banner .banner-wrapper .image-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40%;
    padding-top: 40px;
  }
}
.landing-banner .banner-wrapper .image-container img {
  max-width: 100%;
  height: auto;
}
.animate__animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}
.animate__fadeInUp {
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
}
@-webkit-keyframes fadeInUp {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }
}
@keyframes fadeInUp {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }
}

.landing-banner .banner-wrapper .content-box .banner-tagline {
  color: #ff4a00;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  margin-bottom: 15px;
  letter-spacing: 0.5px;
  padding: 8px 16px;
  background: rgba(255, 74, 0, 0.08);
  border-radius: 30px;
  display: inline-block;
  border: 1px solid rgba(255, 74, 0, 0.2);
  animation: fadeInDown 0.8s ease-in-out, glowPulse 2s infinite;
  text-transform: uppercase;
  position: relative;
}

.landing-banner .banner-wrapper .content-box .banner-tagline::after {
  content: "";
  position: absolute;
  top: 50%;
  right: -15px;
  transform: translateY(-50%);
  width: 6px;
  height: 6px;
  background-color: #ff4a00;
  border-radius: 50%;
  animation: blink 1.5s infinite;
}

@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes glowPulse {
  0% {
    box-shadow: 0 0 0 0 rgba(255, 74, 0, 0.2);
  }
  70% {
    box-shadow: 0 0 0 10px rgba(255, 74, 0, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(255, 74, 0, 0);
  }
}

@keyframes blink {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
}
