.header {
  position: fixed;
  top: 25px;
  left: 50%;
  z-index: 1050;
  width: 95%;
  height: 60px;
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 20px;
  background: rgba(246, 245, 242, 0.8);
  backdrop-filter: blur(12px);
  transform: translateX(-50%);
  transition: top 0.3s ease;
}

@media (min-width: 992px) {
  .header {
    width: 90%;
    top: 60px;
  }
  
  body.banner-closed .header {
    top: 60px;
  }
}


.header .navbar-nav {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.header .navbar-nav .logo {
  color: #ff4a00b8;
  font-family: "clashDisplay";
  font-size: 22px;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;
  text-decoration: none;
}
.header .navbar-nav .logo span {
  color: #ff4a00b8;
  font-family: "clashDisplay";
  font-weight: 400;
  margin-left: 5px;
}
.header .navbar-nav .logo img {
  width: 150px;
}
.navbar-links .nav-list {
  display: none;
  justify-content: end;
  margin-bottom: 0;
  list-style: none;
}
@media (min-width: 992px) {
  .navbar-links .nav-list {
    display: flex;
  }
}
.navbar-links .toggle-btn {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 28px;
  height: 20px;
  border: 0;
  outline: none;
  background: none;
}
.navbar-links .toggle-btn::before,
.navbar-links .toggle-btn::after {
  content: "";
  position: absolute;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: #000000;
}
.navbar-links .toggle-btn::before {
  top: 0px;
}
.navbar-links .toggle-btn::after {
  bottom: 0px;
}
.navbar-links .toggle-btn span {
  width: 100%;
  height: 2px;
  background-color: #000000;
}
@media (min-width: 992px) {
  .navbar-links .toggle-btn {
    display: none;
  }
}
.navbar-links .close-nav {
  outline: 0;
  border: 0;
  padding: 0%;
  background: transparent;
}
.navbar-links .close-nav img {
  width: 30px;
}
.navbar-links .nav-list .nav-link {
  color: #000000;
  font-family: "railway-regular", sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  margin-right: 35px;
}
.navbar-links .nav-list .download-btn {
  padding: 8px 20px;
  color: #fff;
  font-family: "railway-regular", sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  border: 1px solid #000000;
  border-radius: 38px;
  background-color: #000000;
}

.mobileNav-wrapper {
  position: fixed;
  top: 100px;
  left: 50%;
  z-index: 1060;
  width: 95%;
  max-height: calc(100vh - 220px);
  overflow-y: auto;
  transform: translateX(-50%);
  opacity: 0;
  visibility: hidden;
  transition: all 0.4s linear;
}
.mobileNav-wrapper.active {
  opacity: 1;
  visibility: visible;
}
.mobileNav-wrapper .mobileNav-list {
  height: 100%;
  list-style: none;
  padding: 15px;
  border: 1px solid #0000001f;
  border-radius: 18px;
  background: linear-gradient(180deg, #fff 31.35%, #f9e1d6 99.84%);
}
.mobileNav-wrapper .mobileNav-list .mob-nav-btn {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 10px 0px;
  color: #000000;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  outline: 0;
  border: 0;
  border-bottom: 1px solid #0000001f;
  background: transparent;
  cursor: pointer;
  text-decoration: none;
}

.mob-download-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 38px;
  padding: 8px 20px;
  margin-top: 15px;
  color: #fff;
  font-family: "railway-regular", sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  border: 1px solid #000000;
  border-radius: 38px;
  background-color: #000000;
  text-decoration: none;
}
.mobileNav-wrapper .mobileNav-list .mob-nav-btn img {
  width: 20px;
}
.navback-drop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1040;
  background: rgba(0, 0, 0, 0.48);
  backdrop-filter: blur(12px);
  transition: all 0.15s linear;
  opacity: 0;
  visibility: hidden;
}
.navback-drop.show {
  opacity: 1;
  visibility: visible;
}


.dropdown {
  position: relative;
}

.dropdown .nav-link {
  cursor: pointer;
}

.dropdown-content {
  position: absolute;
  top: 100%;
  left: 0;
  min-width: 280px;
  margin-top: 10px;
  padding: 12px;
  border-radius: 12px;
  background: rgba(246, 245, 242, 0.95);
  backdrop-filter: blur(12px);
  border: 1px solid rgba(0, 0, 0, 0.1);
  opacity: 0;
  visibility: hidden;
  transform: translateY(-10px);
  transition: all 0.3s ease;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.dropdown:hover .dropdown-content {
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
}

.dropdown-item-content {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.dropdown-item-content h4 {
  margin: 0;
  font-family: "clash-display", sans-serif;
  font-size: 16px;
  font-weight: 500;
  color: inherit;
}

.dropdown-item-content p {
  margin: 0;
  font-family: "railway-regular", sans-serif;
  font-size: 14px;
  font-weight: 400;
  color: inherit;
  opacity: 0.8;
}

.dropdown-item {
  display: block;
  padding: 12px 15px;
  text-decoration: none;
  border-radius: 8px;
  transition: all 0.2s ease;
  color: #000000;
}

.dropdown-item:hover {
  background-color: #ff4d00;
  color: #ffffff;
}


.dropdown-item:not(:last-child) {
  margin-bottom: 4px;
}
