.btn-wrapper {
  display: inline-block;
}
.btn-wrapper .custom-btn {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 48px;
  padding: 8px 20px;
  color: #fff;
  font-family: "railway-regular", sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  border: 1px solid #ff4a00;
  border-radius: 38px;
  background-color: #ff4a00;
  text-decoration: none;
}
.btn-wrapper .custom-btn:active {
  color: #fff;
  border-color: #ff4a00;
  background-color: #ff4a00;
}
.btn-wrapper .custom-btn img {
  width: 20px;
  margin-left: 10px;
}
.btn-wrapper .custom-btn.outline {
  color: #ff4a00;
  background: transparent;
}
