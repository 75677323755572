.about-page {
  padding-top: 70px;
}


.about-page .header {
  top: 22px;
}

@media (max-width: 991px) {
  .about-page .header {
    top: 22px; /*position on mobile for about page */
  }
}

.page-title-section,
.our-vision,
.our-history,
.our-values,
.our-team,
.existance,
.our-future {
  padding: 4rem 0;
}

.page-title-section .page-title {
  color: #000;
  font-size: 2.7rem;
  line-height: 50px;
  font-weight: 700;
  margin-bottom: 1rem;
  font-family: "clashDisplay", sans-serif;
  @media (min-width: 768px) {
    font-size: 3.75rem;
    line-height: 1.25;
  }
}
.page-title-section .page-title span {
  color: #ff4d00;
}
/* -------------- vision --------------- */

.our-vision .vision-container {
  display: flex;
  justify-content: space-between;
  @media (max-width: 768px) {
    flex-direction: column;
    row-gap: 40px;
  }
}
.vision-container .main-title {
  width: 100%;
  flex: 1;
  font-size: 2rem;
  font-weight: 500;
  line-height: 2.5rem;
  margin-bottom: 0;
  @media (min-width: 768px) {
    width: 60%;
    flex: 0 0 60%;
    font-size: 2.7rem;
    font-weight: 500;
    line-height: 3.375rem;
  }
}
.vision-container .main-title span {
  color: #ff4d00;
}
.vision-container .side-content-wrapper {
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 2.75rem;
  @media (min-width: 768px) {
    width: 30%;
    flex: 0 0 30%;
  }
}
.vision-container .side-content-wrapper .content-card {
  padding: 1.5rem;
  border: 0;
  border-radius: 32px;
  background-color: #f6f5f2;
}
.vision-container .side-content-wrapper .title {
  font-size: 2rem;
  font-weight: 500;
  line-height: 2.5rem;
  margin-bottom: 1rem;
}
.vision-container .side-content-wrapper .text {
  font-size: 20px;
  opacity: 0.8;
  font-weight: 400;
  line-height: 28px;
  margin-bottom: 0;
}

/* -------------- history ------------------ */
.our-history .section-title,
.our-values .section-title,
.our-team .section-title,
.existance .section-title,
.our-future .section-title {
  color: #000;
  text-align: center;
  font-size: 40px;
  font-weight: 700;
  line-height: 48px;
  margin-bottom: 20px;
  font-family: "clashDisplay", sans-serif;
}
.our-history .section-text,
.our-values .section-text,
.our-team .section-text,
.existance .section-text,
.our-future .section-text {
  color: #000000;
  text-align: center;
  font-size: 18px;
  line-height: 26px;
  font-weight: 500;
  margin-bottom: 50px;
  font-family: "jakarta-regular", sans-serif;
}

.history-wrapper {
  display: grid;
  gap: 40px;
  @media (min-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }
}
.history-wrapper .title {
  font-size: 24px;
  font-weight: 700;
  line-height: 36px;
  margin-bottom: 1rem;
}
.history-wrapper .text {
  font-size: 20px;
  opacity: 0.8;
  font-weight: 400;
  line-height: 28px;
  margin-bottom: 0;
}
.history-wrapper .img-wrapper {
  position: relative;
  display: flex;
  justify-content: flex-end;
  height: 397px;
  border-radius: 0.5rem;
  overflow: hidden;
}
.history-wrapper .img-wrapper img {
  object-fit: cover;
  height: 100%;
  width: 100%;
}
/* ----------- Existance ---------------------- */
.existance .existance-card {
  border: 0;
}
.existance .existance-card .card-body {
  display: grid;
  gap: 50px;
  padding: 0;
  margin-bottom: 40px;
  @media (min-width: 768px) {
    grid-template-columns: repeat(3, 1fr);
  }
}
.existance .existance-card .card-item {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-width: 16.875rem;
  height: 19.25rem;
  border: 0.14rem solid hsla(0, 0%, 7%, 0.12);
  border-radius: 1.25rem;
  position: relative;
  cursor: pointer;
  overflow: hidden;
  padding: 3rem;
}
.existance .existance-card .card-item svg {
  color: #ff4d00;
  width: 50px;
  height: 50px;
}
.existance .existance-card .card-text {
  color: #000;
  font-size: 20px;
  font-weight: 500;
  line-height: 36px;
}
/* --------------- our values ---------------- */
/* .our-values .vaues-content {
  display: flex;
  justify-content: space-between;
} */
/* .our-values .vaues-content .values-img {
  flex: 0 0 50%;
  width: 50%;
  height: 450px;
} */
/* .our-values .vaues-content .values-img img {
  border-radius: 0.75rem;
  width: 100%;
  height: 100%;
  object-fit: cover;
} */
/* .our-values .vaues-content .value-accordion-main {
  flex: 0 0 auto;
  width: 41.66666667%;
} */
.our-values .value-accordion-main .accordion-text {
  color: #000;
  font-size: 20px;
  line-height: 28px;
  font-weight: 500;
  text-align: center;
  margin-bottom: 2.5rem;
}
.our-values .value-accordion-main .accordion {
  position: relative;
  display: grid;
  margin-bottom: 10px;
  @media (min-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
    gap: 40px;
  }
}
.our-values .value-accordion-main .accordion .accordion-item {
  border: 0;
}
.our-values .value-accordion-main .accordion .accordion-item .accordion-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #000000;
  font-size: 20px;
  font-weight: 700;
  line-height: 28px;
  padding: 20px 0px;
  border-bottom: 1px solid #0000001a;
  cursor: pointer;
}
.our-values .value-accordion-main .accordion .accordion-header .acc-button {
  display: inline-block;
  width: 20px;
  height: 20px;
  background-size: 100% 100%;
  background-position: center;
}
.our-values
  .value-accordion-main
  .accordion
  .accordion-header:not(.collapsed)
  .acc-button {
  background-image: url("../../assets/img/light_cross.svg");
}
.our-values
  .value-accordion-main
  .accordion
  .accordion-header.collapsed
  .acc-button {
  background-image: url("../../assets/img/light_plus.svg");
}
.our-values .value-accordion-main .accordion .accordion-collapse {
  color: #000000b8;
  font-family: "railway-regular", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px; /* 144.444% */
  padding-bottom: 20px;
}

/* ----------- furure ------------- */
.our-future .future-card {
  border: 0;
}
.our-future .future-card .card-text {
  color: #000;
  font-size: 20px;
  font-weight: 500;
  line-height: 36px;
}
.our-future .future-card .card-text.center {
  text-align: center;
}
.our-future .future-card .card-text span {
  color: #ff4d00;
}
.our-future .future-card .card-text.flex {
  display: flex;
  justify-content: center;
  gap: 40px;
  @media (max-width: 768px) {
    flex-direction: column;
  }
}

/* ------------------------ our team ----------- */

.our-team .tabs-wrapper {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 3rem;
}
.our-team .tabs-wrapper .btn-main {
  padding: 10px;
  border: 1px solid #ff4d00;
  border-radius: 0.5rem;
  @media (max-width: 768px) {
    display: flex;
    width: 100%;
  }
}
.our-team .tabs-wrapper .tabs-button {
  min-width: 180px;
  padding: 0.5rem 2rem;
  align-items: center;
  justify-content: center;
  border-radius: 0.5rem;
  border: 1px solid transparent;
  outline: none;
  background-color: transparent;
  transition: all 0.4s ease;
  @media (max-width: 768px) {
    min-width: auto;
    flex-grow: 1;
  }
}

.our-team .tabs-wrapper .tabs-button.active {
  color: #fff;
  border-color: #ff4d00;
  background-color: #ff4d00;
}

.our-team .tab-content .director-content {
  display: grid;
  gap: 60px;
  max-width: 1024px;
  margin: 0 auto;
  @media (min-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }
}
.our-team .tab-content .team-content {
  display: grid;
  gap: 50px;
  position: relative;
  z-index: 1;
  @media (min-width: 768px) {
    grid-template-columns: repeat(3, 1fr);
  }
}

.our-team .tab-content .director-content .director-card,
.our-team .tab-content .team-content .director-card {
  position: relative;
  width: 100%;
  height: 32.5rem;
  border: 1px solid rgba(6, 2, 22, 0.08);
  border-radius: 16px;
  background: linear-gradient(
    120deg,
    rgba(255, 255, 255, 0.12) 28.09%,
    rgba(255, 74, 0, 0.12) 129.42%
  );
  overflow: hidden;
  transition: all 0.5s ease-in-out;
  cursor: pointer;
}
.our-team .tab-content .director-content .director-card:hover,
.our-team .tab-content .team-content .director-card:hover {
  border-color: #ff4d00;
}

.our-team .tab-content .director-content .director-card .card-header,
.our-team .tab-content .team-content .director-card .card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2rem;
}
.our-team .tab-content .director-content .director-card .card-title,
.our-team .tab-content .team-content .director-card .card-title {
  color: #000;
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  margin-bottom: 0.75rem;
}
.our-team .tab-content .director-content .director-card .card-text,
.our-team .tab-content .team-content .director-card .card-text {
  color: rgba(0, 0, 0, 0.52);
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
}

.our-team .tab-content .director-content .director-card .img-container,
.our-team .tab-content .team-content .director-card .img-container {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 300px;
}
.our-team .tab-content .director-content .director-card .img-container img,
.our-team .tab-content .team-content .director-card .img-container img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: bottom center;
}
.company-details {
  background-color: #fffefe;
  padding: 4rem 0;
  text-align: center;
}

.company-details .section-title {
  font-size: 2.5rem;
  font-weight: 700;
  margin-bottom: 1.5rem;
  color: #000;
}

.company-details .company-name {
  font-size: 1.6rem;
  font-weight: 600;
  margin-bottom: 0.5rem;
  color: #333;
}

.company-details .company-address {
  font-size: 1.1rem;
  color: #555;
  margin: 0;
}