.faq-section {
  text-align: center;
}

.faq-section .faq-accordion-main {
  text-align: left;
  padding: 0px;
}

.leading-mark {
  display: inline-block;
  color: #ff4a00;
  font-size: 14px;
  font-weight: 900;
  line-height: 20px;
  letter-spacing: 1px;
  padding: 5px 15px;
  margin-bottom: 20px;
  text-transform: uppercase;
  border-radius: 40px;
  border: 1px solid rgba(255, 74, 0, 0.3);
  background: rgba(255, 74, 0, 0.1);
}
@media (min-width: 768px) {
  .faq-section .faq-accordion-main {
    padding: 40px 120px 0 120px;
  }
}
.faq-section .faq-accordion-main .accordion-item {
  border: 0;
  border-bottom: 1px solid #0000001a;
}
.faq-section .faq-accordion-main .accordion-header {
  font-family: "jakarta-regular", sans-serif;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #000000;
  font-size: 18px;
  font-weight: 700;
  line-height: 26px;
  padding: 20px 0px;
  border: 0;
  cursor: pointer;
}
.faq-section .faq-accordion-main .accordion-header .acc-button {
  display: inline-block;
  width: 20px;
  height: 20px;
  background-size: 100% 100%;
  background-position: center;
}
.faq-section .faq-accordion-main .accordion-header:not(.collapsed) .acc-button {
  background-image: url("../../assets/img/light_cross.svg");
}
.faq-section .faq-accordion-main .accordion-header.collapsed .acc-button {
  background-image: url("../../assets/img/light_plus.svg");
}
.faq-section .faq-accordion-main .accordion-collapse {
  font-family: "railway-regular", sans-serif;
  color: #000000b8;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px; 
  padding-bottom: 20px;
}

.faq-section .faq-cardMain .leading-mark {
  font-family: "clash-display", sans-serif;
}

.faq-section .faq-cardMain .landing-title {
  font-family: 'clashDisplay';
}


