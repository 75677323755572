.footer {
  padding-top: 10px;
  background: rgba(255, 77, 0, 0.1);
}
.footer-section .mobile-img-section {
  position: relative;
  z-index: 10;
  display: flex;
  justify-content: space-between;
  padding: 0;
  margin-bottom: 110px;
}
@media (min-width: 992px) {
  .footer-section .mobile-img-section {
    padding: 40px 120px 0 120px;
    margin-bottom: 0px;
  }
}

.mobile-img-section .img-one {
  padding-top: 150px;
}
.mobile-img-section .img-15pro {
  padding-top: 80px;
}
.mobile-img-section .img-one img,
.mobile-img-section .img-15pro img,
.mobile-img-section .img-13pro img {
  position: relative;
  max-width: 100%;
  height: auto;
  transition: all 0.3s ease;
}
.footer-section .title-box {
  position: relative;
  z-index: 1;
  top: -80px;
}
.footer-section .title-box .chanakya-title {
  display: none;
  color: #ff4a00;
  text-align: center;
  font-family: "clashDisplay";
  font-size: 100px;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 20px;
  opacity: 0.16;
}

@media (min-width: 992px) {
  .footer-section .title-box .chanakya-title {
    display: block;
    font-size: 140px;
  }
}
@media (min-width: 1200px) {
  .footer-section .title-box .chanakya-title {
    font-size: 180px;
  }
}

.footer-section .title-box .btn-wrapper {
  display: block;
}
.footer-section .title-box .btn-wrapper .custom-btn {
  justify-content: center;
  height: 50px;
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
}
@media (min-width: 768px) {
  .footer-section .title-box .btn-wrapper .custom-btn {
    height: 60px;
  }
}
@media (min-width: 992px) {
  .footer-section .title-box .btn-wrapper .custom-btn {
    height: 80px;
    font-size: 24px;
    line-height: 20px;
  }
}
.footer-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
}
@media (min-width: 992px) {
  .footer-wrapper {
    flex-direction: row;
  }
}
.footer-wrapper .copyWrite-box {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 20px;
}
@media (min-width: 768px) {
  .footer-wrapper .copyWrite-box {
    flex-direction: row;
  }
}
@media (min-width: 992px) {
  .footer-wrapper .copyWrite-box {
    width: auto;
    margin-bottom: 0px;
  }
}
.footer-wrapper .copyWrite-box .copywrite-text {
  color: #000000b8;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  margin-right: 20px;
  margin-bottom: 20px;
}
@media (min-width: 768px) {
  .footer-wrapper .copyWrite-box .copywrite-text {
    flex-direction: row;
    margin-bottom: 0px;
  }
}
.footer-wrapper .copyWrite-box .btn-list {
  display: flex;
  flex-direction: column;
  list-style: none;
  margin-bottom: 0;
  padding-left: 0;
}
@media (min-width: 768px) {
  .footer-wrapper .copyWrite-box .btn-list {
    flex-direction: row;
  }
}
.footer-wrapper .copyWrite-box .btn-item {
  margin-right: 20px;
}
.footer-wrapper .copyWrite-box .btn-item:first-child {
  margin-bottom: 15px;
}
@media (min-width: 768px) {
  .footer-wrapper .copyWrite-box .btn-item:first-child {
    margin-bottom: 0px;
  }
}
.footer-wrapper .copyWrite-box .btn-text {
  color: #000000b8;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  text-decoration-line: underline;
  margin-bottom: 0px;
  cursor: pointer;
}

.footer-wrapper .made-with-text {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  color: #ff4a00;
  font-size: 14px;
  font-weight: 700;
  line-height: 24px;
  margin-bottom: 0px;
}
@media (min-width: 768px) {
  .footer-wrapper .made-with-text {
    justify-content: flex-end;
  }
}
@media (min-width: 992px) {
  .footer-wrapper .made-with-text {
    width: auto;
  }
}
.footer-wrapper .made-with-text img {
  width: 25px;
  margin-right: 5px;
}
