.landingPage-wrapper {
  background-color: #fff;
}

.macbook-showcase {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem 0;
}

.macbook-frame {
  position: relative;
  width: 90%;
  max-width: 1200px;
  background: linear-gradient(135deg, #2c2c2c, #1a1a1a);
  border-radius: 20px;
  box-shadow: 0 50px 100px rgba(0, 0, 0, 0.4), 0 15px 50px rgba(0, 0, 0, 0.3);
  overflow: hidden;
  transform: perspective(2000px) rotateX(4deg);
  transition: all 0.3s ease;
}

.macbook-frame:hover {
  transform: perspective(2000px) rotateX(3deg) scale(1.01);
}

.macbook-lid {
  background: linear-gradient(to bottom, #1e1e1e, #121212);
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  padding: 15px;
}

.macbook-display {
  position: relative;
  background: #0a0a0a;
  border-radius: 15px;
  overflow: hidden;
}

.macbook-top-bar {
  height: 20px;
  background: linear-gradient(to right, #2c2c2c, #1a1a1a);
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}

.macbook-camera {
  position: absolute;
  top: 10px;
  left: 50%;
  transform: translateX(-50%);
  width: 8px;
  height: 8px;
  background: #444;
  border-radius: 50%;
  z-index: 10;
}

.macbook-screen {
  position: relative;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
}

.macbook-video {
  width: 100%;
  height: auto;
  object-fit: cover;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
}

.macbook-keyboard {
  height: 28px;
  background: linear-gradient(to bottom, #1a1a1a, #121212);
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.macbook-touchpad {
  width: 100px;
  height: 8px;
  background: #2c2c2c;
  border-radius: 10px;
}

.video-controls {
  margin-top: 20px;
  display: flex;
  gap: 20px;
  background: rgba(255, 255, 255, 0.1);
  padding: 12px 25px;
  border-radius: 40px;
  backdrop-filter: blur(10px);
}

.control-btn {
  background: none;
  border: none;
  color: white;
  cursor: pointer;
  transition: transform 0.2s ease;
  display: flex;
  align-items: center;
  justify-content: center;
}

.control-btn:hover {
  transform: scale(1.2);
}

@media (max-width: 768px) {
  .macbook-frame {
    width: 95%;
  }
}

.landingPage-wrapper .leading-mark {
  display: inline-block;
  color: #ff4a00;
  font-size: 12px;
  font-weight: 900;
  line-height: 20px;
  letter-spacing: 1px;
  padding: 5px 15px;
  margin-bottom: 20px;
  text-transform: uppercase;
  border-radius: 40px;
  border: 1px solid rgba(255, 74, 0, 0.3);
  background: rgba(255, 74, 0, 0.1);
}
.landingPage-wrapper .landing-title {
  font-family: 'Clash Display', sans-serif;
  color: #000;
  text-align: center;
  font-size: 40px;
  font-weight: 700;
  line-height: 48px;
  margin-bottom: 20px;
}
@media (min-width: 768px) {
  .landingPage-wrapper .landing-title h3{
    font-size: 40px;
    line-height: 48px;
  }
}
.landingPage-wrapper .landing-text,
.landingPage-wrapper span,
.section-subtitle {
  font-family: 'Railway', sans-serif;
  color: #000000;
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  margin-bottom: 30px;
}
@media (min-width: 768px) {
  .landingPage-wrapper .landing-text {
    font-size: 18px;
    line-height: 26px;
  }
}

.landing-banner,
.banner-bottom,
.explore-section,
.everything-you-do-sec,
.faq-section,
.footer-section,
.footer,
.banner-section,
.how-it-works,
.feature-section,
.powerUp-section,
.edu-page-footer {
  padding-bottom: 50px;
  padding-top: 50px;
}

.banner-bottom .bottom-card {
  text-align: center;
  border: 0px;
  border-radius: 32px;
  background: linear-gradient(
    120deg,
    rgba(255, 255, 255, 0.12) 28.09%,
    rgba(255, 74, 0, 0.12) 129.42%
  );
}
.banner-bottom .bottom-card .bCard-body {
  padding: 35px 40px;
}
@media (min-width: 768px) {
  .banner-bottom .bottom-card .bCard-body {
    padding: 90px 120px;
  }
}
.banner-bottom .bottom-card .bCard-body .card-text {
  color: rgba(0, 0, 0, 0.52);
  font-family: "railway-regular", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 26px;
  margin-bottom: 20px;
}
.banner-bottom .bottom-card .bCard-body .card-text span div {
  color: rgba(0, 0, 0) !important;
}
@media (min-width: 992px) {
  .banner-bottom .bottom-card .bCard-body .card-text {
    font-size: 28px;
    line-height: 36px;
  }
}
.banner-bottom .bottom-card .bCard-body .card-text.mb5 {
  margin-bottom: 40px;
}
@media (min-width: 992px) {
  .banner-bottom .bottom-card .bCard-body .card-text.mb5 {
    margin-bottom: 50px;
  }
}
.banner-bottom .bottom-card .bCard-body .card-text span {
  color: rgb(0, 0, 0);
}
.banner-bottom .bottom-card .list {
  text-align: left;
}
.banner-bottom .bottom-card .list .list-item {
  display: flex;
  align-items: center;
  column-gap: 15px;
  margin-bottom: 10px;
}
.banner-bottom .bottom-card .list .list-item .icon {
  width: 1.6rem;
  height: 1.6rem;
  color: #ff4d00;
  flex-shrink: 0;
}

.banner-bottom .number-card {
  border: 0;
  background: 0;
}
.banner-bottom .number-card .card-body .card-title {
  color: #ff4a00;
  text-align: center;
  font-size: 36px;
  font-weight: 700;
  line-height: 36px;
}
.banner-bottom .number-card .card-body .card-text {
  color: #000;
  font-size: 20px;
  font-weight: 500;
  line-height: 36px;
  margin-bottom: 0px;
}
@media (min-width: 768px) {
  .banner-bottom .number-card .card-body .card-title {
    font-size: 28px;
    font-weight: 700;
    line-height: 36px;
  }
  .banner-bottom .number-card .card-body .card-text {
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
  }
}
@media (min-width: 992px) {
  .banner-bottom .number-card .card-body .card-title {
    font-size: 40px;
    line-height: 36px;
  }
  .banner-bottom .number-card .card-body .card-text {
    font-size: 20px;
    line-height: 36px;
  }
}

/* ------ everything you need ----------- */

.everything-you-do-sec .everything-you-doNain .landing-text {
  max-width: 900px;
  margin-left: auto;
  margin-right: auto;
}

.everything-you-do-sec .everything-card {
  height: 100%;
  border: 1px solid rgba(255, 74, 0, 0.1);
  border-radius: 32px;
  background-color: #fff;
  transition: all 0.3s ease;
}
.everything-you-do-sec .everything-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 20px rgba(255, 74, 0, 0.1);
}
.everything-you-do-sec .everything-card .card-body {
  padding-left: 20px;
  padding-right: 20px;
}
@media (min-width: 992px) {
  .everything-you-do-sec
    .col-lg-8
    .everything-card
    .card-body
    .everything-text {
    width: 70%;
    margin-left: auto;
    margin-right: auto;
  }
}

.everything-you-do-sec .everything-card img {
  max-width: 100%;
  height: auto;
}

.explore-section {
  text-align: center;
}

.explore-section .subscription-main {
  padding: 0px 15px 0 15px;
}

@media (min-width: 768px) {
  .explore-section .subscription-main {
    padding: 30px 120px 0 120px;
  }
}

.explore-section .subscription-card {
  padding: 20px;
  text-align: left;
  border-radius: 16px;
  border: 1px solid rgba(6, 2, 22, 0.08);
  transition: all 0.3s ease;
}

.explore-section .subscription-card.active {
  background: linear-gradient(
    120deg,
    rgba(255, 255, 255, 0.12) 28.09%,
    rgba(255, 74, 0, 0.12) 129.42%
  );
  border: 1px solid rgba(255, 74, 0, 0.2);
}

@media (max-width: 768px) {
  .explore-section .subscription-card .card-body {
    padding: 0px;
  }
}

.explore-section .subscription-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 20px rgba(255, 74, 0, 0.1);
}

.explore-section .subscription-card .card-body {
  padding: 20px;
}

.explore-section .subscription-card .subs-title {
  display: flex;
  align-items: center;
  color: #000;
  font-size: 24px;
  font-weight: 700;
  line-height: 36px;
}

.explore-section .subscription-card .subs-title span {
  padding: 2px 8px;
  margin-left: 10px;
  text-align: center;
  font-size: 14px;
  font-weight: 700;
  line-height: 24px;
  text-transform: uppercase;
  border-radius: 8px;
}

.explore-section .subscription-card .subs-title span.free {
  color: #000;
  background-color: #0000001f;
}

.explore-section .subscription-card .subs-title span.pro {
  color: #fff;
  background: linear-gradient(90deg, #ff4a00 0%, #ff7a00 100%);
  box-shadow: 0 2px 4px rgba(255, 74, 0, 0.2);
}

.explore-section .subscription-card .subs-subtitle {
  color: #000;
  font-size: 20px;
  font-weight: 500;
  line-height: 28px;
}

.explore-section .subscription-card .subs-text {
  color: rgba(0, 0, 0, 0.52);
  font-size: 20px;
  font-weight: 500;
  line-height: 28px;
}

.explore-section .subscription-card.exam-sub-card {
  height: 100%;
  border: 2px solid#e5e7eb;
  border-radius: 0.5rem;
}

.explore-section .subscription-card.exam-sub-card.most-popular {
  border-color: #ff4d15;
}

.explore-section .subscription-card.exam-sub-card.comming-soon {
  opacity: 0.6;
  pointer-events: none;
}

.explore-section .footer-text {
  text-align: center;
  color: #6b7280;
  font-size: 0.875rem;
  line-height: 1.25rem;
  margin-bottom: 1rem;
}

.explore-section .footer-text a {
  color: #ff4d15;
}

.popular-tag {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0.5rem 0.75rem;
  color: #ffffff;
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: 700;
  text-transform: uppercase;
  background-color: #ff4d15;
  border-top-right-radius: 0.4rem;
}

.explore-section .subscription-card.exam-sub-card .subs-title {
  font-size: 1.5rem;
  line-height: 2rem;
  font-weight: 700;
  margin-bottom: 1.25rem;
}

.explore-section .subscription-card.exam-sub-card .subs-subtitle {
  font-size: 1.875rem;
  line-height: 2.25rem;
  font-weight: 700;
  margin-bottom: 1rem;
}

.explore-section .subscription-card.exam-sub-card .subs-subtitle span {
  text-decoration-line: line-through;
  color: #6b7280;
  font-size: 0.875rem;
  line-height: 1.25rem;
}

.explore-section .subscription-card.exam-sub-card .subs-text {
  font-size: 0.875rem;
  line-height: 1.25rem;
  margin-bottom: 1rem;
}

.explore-section .subscription-card.exam-sub-card .subs-text.discount {
  color: #ff4d15;
}

.explore-section .subscription-card.exam-sub-card .btn-wrapper {
  width: 100%;
}

.explore-section .subscription-card.exam-sub-card .btn-wrapper .custom-btn {
  width: 100%;
  justify-content: center;
}

.explore-section .subscription-card.exam-sub-card .black-btn {
  border-color: #181818;
  background-color: #18181b;
}

.explore-section .subscription-card.exam-sub-card .blue-btn {
  border-color: #9333ea;
  background-color: #9333ea;
}

.btn-box .btn-wrapper {
  display: block;
}

.btn-box .btn-wrapper .custom-btn {
  width: 100%;
}

@media (min-width: 992px) {
  .btn-box .btn-wrapper {
    display: inline-block;
  }
  .btn-box .btn-wrapper .custom-btn {
    width: auto;
  }
}

.explore-section .subscription-card .key-pointsMain .keyPoints-text {
  color: #000000;
  font-size: 16px;
  font-weight: 500;
  line-height: 26px;
  margin-bottom: 10px;
}

.explore-section .subscription-card .key-pointsMain .keyPoints-text span {
  font-weight: 700;
}

.explore-section .subscription-card .key-pointsMain .icon {
  width: 1.6rem;
  height: 1.6rem;
  color: #ff4d00;
  flex-shrink: 0;
}

.explore-section .subscription-card .key-pointsMain img {
  width: 20px;
  margin-top: 4px;
  margin-right: 10px;
}

.form-switch {
  display: flex;
  align-items: center;
}

.form-switch input {
  display: none;
}

.form-switch i {
  width: 40px;
  height: 20px;
  background: #ccc;
  border-radius: 10px;
  margin-right: 10px;
  position: relative;
  cursor: pointer;
}

.form-switch i:before {
  content: "";
  width: 16px;
  height: 16px;
  background: #fff;
  border-radius: 50%;
  position: absolute;
  top: 2px;
  left: 2px;
  transition: 0.3s;
}

.form-switch input:checked + i {
  background: #ff4a00;
}

.form-switch input:checked + i:before {
  left: 22px;
}

.form-switch span {
  font-size: 14px;
}

.subs-title .discount-badge {
  display: none;
  background-color: #ff4a00;
  color: white;
  font-weight: bold;
  font-size: 0.75rem;
  padding: 0.25rem 0.5rem;
  border-radius: 3px;
  z-index: 1;
}

@media (min-width: 768px) {
  .subs-title .discount-badge {
    display: block;
  }
}

.original-price {
  text-decoration: line-through;
  color: #888;
}

.discounted-price {
  color: #ff4a00;
  font-weight: bold;
}

.discount-badge {
  background-color: #ff4a00;
  color: #fff;
  padding: 0.2em 0.5em;
  border-radius: 0.2em;
  font-size: 0.9em;
  font-weight: bold;
}

.discounted-price-text {
  color: #ff4a00;
  font-size: 15px;
}

.card-body {
  position: relative;
  padding: 1.5em;
}

.discounted-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1.5rem;
}

.discounted-content .discount-badge {
  display: block;
  background-color: #ff4a00;
  color: #fff;
  padding: 0.2em 0.5em;
  border-radius: 0.5em;
  font-size: 0.9em;
  font-weight: bold;
}

.desktop-toggle {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@media (min-width: 768px) {
  .discounted-content {
    display: none;
  }

  .discounted-content {
    justify-content: flex-end;
  }
}

@media (max-width: 768px) {
  .desktop-toggle .form-switch {
    display: none;
  }
}

.subs-title {
  display: flex;
  align-items: center;
  font-size: 1.5em;
}

.subs-subtitle {
  font-size: 1.2em;
  font-weight: bold;
}

.keyPoints-text {
  margin-left: 0.5em;
}

.key-pointsMain {
  margin-top: 1em;
}

/* ------------- privacy policy and terms of services------------- */

.priTos-wrapper {
  margin-top: 100px;
}
.priTos-wrapper .priTos-card {
  height: 100%;
  border: 0;
  border-radius: 32px;
  background-color: #f6f5f2;
  overflow: hidden;
}
.priTos-wrapper .priTos-card .card-header,
.priTos-wrapper .priTos-card .card-footer {
  background-color: #f6f5f2;
}
.priTos-wrapper .priTos-card .title {
  color: #000;
  font-size: 32px;
  font-weight: 700;
  line-height: normal;
}
@media (min-width: 768px) {
  .priTos-wrapper .priTos-card .title {
    font-size: 40px;
    line-height: 48px;
  }
}

.priTos-wrapper .priTos-card .card-title {
  color: rgb(6, 2, 22);
  font-size: 20px;
  font-weight: 600;
  line-height: 28px;
  margin-bottom: 10px;
}
.priTos-wrapper .priTos-card .card-subtitle {
  color: rgb(6, 2, 22);
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
  margin-bottom: 10px;
}
.priTos-wrapper .priTos-card .card-text {
  color: rgb(6, 2, 22);
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  margin-bottom: 10px;
}

/* ------------ education page ------------- */
.education-page {
  overflow-x: hidden;
}
.education-page-main {
  padding-top: 60px;
}
.education-page-main .section-title {
  font-family: "clashDisplay", sans-serif;
  font-weight: 600;
  font-size: 2.5rem;
  line-height: 1.2;
  color: #000;
  margin-bottom: 1rem;
  text-align: center;
}

.education-page-main .banner-section .banner-wrapper {
  display: grid;
  gap: 3rem;
  align-items: center;
  margin-bottom: 3rem;
  @media (min-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }
}
.education-page-main .banner-section .banner-wrapper .banner-title {
  font-family: "clashDisplay", sans-serif;
  font-weight: 600;
  font-size: 3.5rem;
  line-height: 1.1;
  color: #000;
  margin-bottom: 1.5rem;
}

.education-page-main .banner-section .banner-wrapper .banner-text {
  font-family: 'Plus Jakarta Sans', sans-serif;
  font-size: 1.25rem;
  line-height: 1.6;
  margin-bottom: 2rem;
  color: #4a4a4a;
  max-width: 600px;
}

@media (max-width: 768px) {
  .education-page-main .banner-section .banner-wrapper .banner-title {
    font-size: 2.5rem;
  }
  
  .education-page-main .banner-section .banner-wrapper .banner-text {
    font-size: 1.125rem;
  }
}

.education-page-main .banner-right .banner-card {
  padding: 2rem;
  box-shadow: 0 20px 25px -5px rgb(0 0 0 / 0.1),
    0 8px 10px -6px rgb(0 0 0 / 0.1);
}
.education-page-main .banner-right .banner-card .card-title {
  color: #000;
  font-size: 24px;
  line-height: 32px;
  font-weight: 700;
  margin-bottom: 1.25rem;
}
.education-page-main .banner-right .banner-card .card-list {
  padding-left: 0;
  list-style: none;
}
.education-page-main .banner-right .banner-card .card-list-item {
  display: flex;
  align-items: center;
  color: rgb(75, 85, 99);
  gap: 0.5rem;
  font-size: 18px;
  line-height: 28px;
  font-weight: 400;
  margin-bottom: 0.75rem;
}
.education-page-main .banner-right .banner-card .card-list-item:last-child {
  margin-bottom: 0;
}
.education-page-main .banner-right .banner-card .icon {
  color: #ff4d00;
}
.education-page-main .banner-section .video-wrapper {
  position: relative;
}
.education-page-main .banner-section .video-wrapper .video {
  object-fit: cover;
  width: 100%;
  height: 100%;
  border-radius: 0.5rem;
}
.education-page-main .banner-section .video-wrapper .videoControl {
  position: absolute;
  bottom: 40px;
  right: 40px;
  width: 6.375rem;
  height: 2.5rem;
  border: 0.14rem solid #ff4a00;
  border-radius: 0.25rem;
  color: white;
  background: #ff4a00;
}
.education-page-main .banner-section .video-wrapper span {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}
.education-page-main .banner-section .video-wrapper .muteBtn {
  position: absolute;
  left: 40px;
  bottom: 40px;
  padding: 10px;
  /* width: 35px; */
  /* height: 35px; */
  border: 0;
  border-radius: 50%;
  background: #ff4a00;
  outline: none;
}
.education-page-main .banner-section .video-wrapper .muteUnmute {
  width: 100%;
}
/* ------------ how it works ---------------- */
.education-page-main .works-card-main .works-cards-wrapper {
  position: relative;
  display: flex;
  justify-content: space-between;
  column-gap: 50px;
  padding-bottom: 3rem;
  margin-bottom: 2rem;
  @media (max-width: 768px) {
    flex-direction: column;
    row-gap: 30px;
  }
}
.education-page-main .works-cards-wrapper:nth-child(even) {
  @media (min-width: 768px) {
    flex-direction: row-reverse;
  }
}
.education-page-main .works-cards-wrapper .wrapper-step {
  position: absolute;
  top: 0;
  left: 50%;
  width: 1px;
  height: 100%;
  display: flex;
  align-items: center;
  border-left: 1px dashed #ff4d00;
  transform: translateX(-50%);
  @media (max-width: 768px) {
    left: 95%;
  }
}
.education-page-main .works-cards-wrapper .wrapper-step span {
  position: absolute;
  left: -22px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 45px;
  height: 45px;
  font-size: 16px;
  line-height: 28px;
  font-weight: 500;
  color: #ffffff;
  background-color: #ff4d00;
  border-radius: 100px;
}
.education-page-main .works-cards-wrapper .content-wrapper {
  width: 80%;
  flex: 0 0 80%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  @media (min-width: 768px) {
    width: 30%;
    flex: 0 0 30%;
  }
}
.education-page-main .works-cards-wrapper .content-wrapper .work-title {
  display: flex;
  align-items: center;
  column-gap: 10px;
  color: black;
  font-size: 24px;
  line-height: 32px;
  font-weight: 600;
  margin-bottom: 0.5rem;
}
.education-page-main
  .works-cards-wrapper
  .content-wrapper
  .work-title
  .work-card-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  background-color: rgba(255, 77, 0, 0.1);
}
.education-page-main
  .works-cards-wrapper
  .content-wrapper
  .work-title
  .work-card-icon
  .icon {
  width: 1.6rem;
  height: 1.6rem;
  color: #ff4d00;
}
.education-page-main .works-cards-wrapper .content-wrapper .work-text {
  color: black;
  font-size: 18px;
  line-height: 22px;
  font-weight: 400;
  margin-bottom: 1rem;
}
.education-page-main .works-cards-wrapper .img-main {
  width: 80%;
  flex: 0 0 80%;
  padding: 20px;
  border: 1px solid #e4e4e7;
  border-radius: 0.5rem;
  transition: all 0.3s ease-in-out;
  box-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);
  @media (min-width: 768px) {
    width: 40%;
    flex: 0 0 40%;
  }
}
.education-page-main .works-cards-wrapper .img-main:hover {
  box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
}
.education-page-main .works-cards-wrapper .img-main img {
  max-width: 100%;
  height: auto;
}

/* -------------- additional feature ---------------- */

.education-page-main .feature-section {
  background: linear-gradient(to right, #fff5f2, #f5f9ff, #f5f9ff);
  padding: 50px 0;
}

.education-page-main .feature-section .everything-card {
  height: 100%;
  border: 1px solid rgba(255, 74, 0, 0.1);
  border-radius: 32px;
  background-color: #fff;
  transition: all 0.3s ease;
}

.education-page-main .feature-section .everything-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 20px rgba(255, 74, 0, 0.1);
}

.education-page-main .feature-section .everything-card .card-body {
  padding: 2rem;
}

/* Feature Section Content Styling */
.feature-content {
  display: flex;
  flex-direction: column;
  padding: 0.5rem 0;
}

.feature-heading {
  margin-bottom: 1rem;
}

.feature-heading .text-heading {
  color: #000;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1.3;
  margin: 0;
  transition: color 0.3s ease;
}

.everything-card:hover .feature-heading .text-heading {
  color: #ff4d00;
}

.feature-text {
  color: #4b5563;
  font-size: 1.125rem;
  font-weight: 500;
  line-height: 1.6;
}

/* Improve responsiveness */
@media (max-width: 768px) {
  .feature-heading .text-heading {
    font-size: 1.25rem;
  }
  
  .feature-text {
    font-size: 1rem;
  }
}

/* Keep the highlight-text styles */
.highlight-text {
  color: #ff4d00;
  font-weight: 700;
  position: relative;
  display: inline-block;
}

.everything-card:hover .highlight-text {
  text-decoration: none;
  transform: translateY(-1px);
  transition: all 0.2s ease;
}

.education-page-main .feature-section .section-subtitle {
  color: black;
  font-size: 1.3rem;
  text-align: center;
  margin-bottom: 3rem;
}

@media (min-width: 992px) {
  .feature-card-wide, 
  .feature-card-standard {
    width: 50%;
    max-width: 50%;
    flex: 0 0 50%;
  }
  
  .feature-card-wide .feature-image-wrapper,
  .feature-card-standard .feature-image-wrapper {
    height: 250px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  }
}

/* Add responsive sizing for smaller screens */
@media (max-width: 991px) {
  .feature-card-wide,
  .feature-card-standard {
  width: 100%;
  max-width: 100%;
  }
}

@media (min-width: 992px) {
  
  
  .education-page-main .feature-section .col-lg-8 .everything-card  {
    width: 70%;
    margin-left: auto;
    margin-right: auto;
  }
}

/* --------------- power up section ----------------------- */

.education-page-main .powerUp-section {
  text-align: center;
  background: rgba(255, 77, 0, 0.1)
}
.education-page-main .powerUp-section .tag-main {
  display: flex;
  justify-content: center;
  margin-bottom: 2rem;
}

.education-page-main .powerUp-section .tag-main .section-tag {
  color: #ff4a00;
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  text-transform: uppercase;
  padding: 0.25rem 1.25rem;
  border-radius: 20px;
  background-color: rgba(255, 77, 0, 0.1);
}

.education-page-main .powerUp-section .powerUp-text {
  max-width: 1024px;
  color: #000;
  font-size: 36px;
  line-height: 40px;
  font-weight: 700;
  margin-bottom: 1.75rem;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}
.education-page-main .powerUp-section .accessBox-main {
  display: flex;
  justify-content: center;
}
.education-page-main .powerUp-section .access-text {
  padding: 20px 40px;
  color: #fff;
  font-size: 20px;
  font-weight: 700;
  line-height: 28px;
  border-radius: 15px;
  background-color: #000;
}
/* ---------------- education footer ------------------- */
.edu-page-footer {
  text-align: center;
  background-color: #000;
}
.edu-page-footer .footer-text {
  color: #fff;
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  margin-bottom: 1rem;
}
.edu-page-footer .footer-text:last-child {
  margin-bottom: 0;
}


.whatsapp-banner {
  background: #ff4a00;
  color: white;
  padding: 8px 0;
  position: relative;
  overflow: hidden;
  z-index: 1000;
}

.whatsapp-banner-content {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
  position: relative;
}

.whatsapp-icon-wrapper {
  position: relative;
  width: 24px;
  height: 24px;
  flex-shrink: 0;
}

.whatsapp-icon {
  width: 24px;
  height: 24px;
  position: relative;
  z-index: 2;
  animation: bounce 2s infinite;
}

.pulse-ring {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 24px;
  height: 24px;
  background: rgba(255, 255, 255, 0.4);
  border-radius: 50%;
  animation: pulse 2s infinite;
}

.banner-text-wrapper {
  flex: 1;
  min-width: 0; 
}

.whatsapp-banner p {
  margin: 0;
  font-size: 15px;
  font-weight: 600;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.mobile-subtext {
  display: none;
  font-size: 12px;
  opacity: 0.9;
  margin-top: 2px !important;
}

.join-now-btn {
  display: flex;
  align-items: center;
  gap: 8px;
  background: white;
  color: #ff4a00;
  font-weight: 600;
  text-decoration: none;
  padding: 8px 16px;
  border-radius: 40px;
  transition: all 0.2s ease;
}

.join-now-btn .whatsapp-icon {
  width: 20px;
  height: 20px;
}

.join-now-btn:hover {
  background: #f0f0f0;
  transform: translateY(-1px);
  box-shadow: 0 2px 5px rgba(0,0,0,0.1);
}

@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-5px);
  }
  60% {
    transform: translateY(-3px);
  }
}

@keyframes pulse {
  0% {
    transform: translate(-50%, -50%) scale(1);
    opacity: 1;
  }
  100% {
    transform: translate(-50%, -50%) scale(2);
    opacity: 0;
  }
}

@media (max-width: 768px) {
  .whatsapp-banner {
    padding: 12px 0;
  }

  .whatsapp-banner-content {
    flex-direction: column;
    gap: 8px;
    padding: 0 40px;
    text-align: center;
  }
  
  .banner-text-wrapper {
    width: 100%;
  }

  .whatsapp-banner p {
    font-size: 14px;
    white-space: normal;
    line-height: 1.3;
  }

  .mobile-subtext {
    display: block;
  }

  .join-now-btn {
    justify-content: center;
  }
}

@media (max-width: 360px) {
  .whatsapp-banner-content {
    padding: 0 30px;
  }

  .whatsapp-banner p {
    font-size: 13px;
  }

  .mobile-subtext {
    font-size: 11px;
  }

  .join-now-btn {
    font-size: 13px;
    padding: 6px 12px;
  }
}


/* ---------------- For chat page header ---------------- */
.header {
  position: fixed;
  top: 40px;
  left: 50%;
  z-index: 999;
  width: 95%;
  height: 60px;
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 20px;
  background: rgba(246, 245, 242, 0.8);
  backdrop-filter: blur(12px);
  transform: translateX(-50%);
  transition: top 0.3s ease;
}

@media (min-width: 992px) {
  .header {
    width: 90%;
    top: 60px;
  }
}


.chat-page .header {
  top: 22px; 
}

@media (max-width: 991px) {
  .chat-page .header {
    top: 27px; 
  }
}


.education-page .header {
  top: 20px; 
}

/* Google Startup Logos Section */
.google-startups-wrapper {
  padding: 30px 20px;
  text-align: center;
  background: linear-gradient(to right, #fff5f2, #f5f9ff, #f5f9ff);
  border-radius: 32px;
  margin-bottom: 3rem;
}

.google-startups-title {
  color: #000;
  font-size: 30px;
  font-weight: 700;
  line-height: 32px;
  margin-bottom: 30px;
}

.google-startups-logos {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 40px;
}

.google-startups-logo-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: transform 0.3s ease;
}

.google-startups-logo-item:hover {
  transform: translateY(-5px);
}

.google-startups-logo-image {
  height: 100px;
  max-width: 280px;
  object-fit: contain;
  margin-bottom: 15px;  
  display: flex;
  justify-content: center;
}

.google-startups-logo-name {
  color: #4b5563;
  font-size: 16px;  
  font-weight: 500;
  text-align: center;
}

.google-startups-logo-relationship {
  color: #4b5563;
  font-size: 16px;  
  font-weight: 600;
  margin-bottom: 10px; 
}

@media (max-width: 768px) {
  .google-startups-wrapper {
    padding: 20px 15px;
  }
  
  .google-startups-logos {
    gap: 20px;
  }
  
  .google-startups-logo-image {
    height: 70px;
    max-width: 280px;
  }
  
  .google-startups-logo-name {
    font-size: 14px; 
  }
  
  .google-startups-logo-relationship {
    font-size: 14px;  
  }
}

@media (max-width: 991px) {
  .education-page .header {
    top: 20px; 
  }
}

/* Add new section divider styling */
.section-divider {
  text-align: center;
  margin: 3rem 0;
  color: #ff4d00;
  font-size: 1.2rem;
  font-weight: 600;
  position: relative;
}

.section-divider::before,
.section-divider::after {
  content: "";
  position: absolute;
  top: 50%;
  width: 30%;
  height: 1px;
  background: rgba(255, 77, 0, 0.3);
}

.section-divider::before {
  left: 0;
}

.section-divider::after {
  right: 0;
}

.testimonials-section {
  padding: 50px 0;
  background: linear-gradient(to right, #fff5f2, #f5f9ff, #f5f9ff);
}

.testimonials-section .bottom-card {
  text-align: left;
  border: 0px;
  border-radius: 32px;
  background: linear-gradient(
    120deg,
    rgba(255, 255, 255, 0.12) 28.09%,
    rgba(255, 74, 0, 0.12) 129.42%
  );
}


/* -------------- Target Audience Section ---------------- */
.target-audience {
  padding: 50px 0;
}

.audience-card {
  background: white;
  border: 1px solid rgba(255, 74, 0, 0.1);
  border-radius: 16px;
  padding: 2rem;
  margin-bottom: 2rem;
  transition: all 0.3s ease;
}

.audience-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 20px rgba(255, 74, 0, 0.1);
}

.audience-title {
  display: flex;
  align-items: center;
  gap: 1rem;
  color: #000;
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 1rem;
}

.audience-icon {
  width: 2.5rem;
  height: 2.5rem;
  padding: 0.5rem;
  border-radius: 50%;
  background: rgba(255, 74, 0, 0.1);
  color: #ff4d00;
  display: flex;
  align-items: center;
  justify-content: center;
}

.audience-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.audience-item {
  display: flex;
  align-items: center;
  gap: 0.75rem;
  color: #4b5563;
  font-size: 16px;
  line-height: 1.6;
  margin-bottom: 0.5rem;
}

.audience-check {
  color: #ff4d00;
  flex-shrink: 0;
}

@media (min-width: 768px) {
  .testimonial-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 2rem;
  }

  .audience-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2rem;
  }
}

/* Section headers */
.section-header {
  text-align: center;
  margin-bottom: 3rem;
}

.section-title {
  color: #000;
  font-size: 2.5rem;
  font-weight: 700;
  margin-bottom: 1rem;
  font-family: 'Clash Display', sans-serif;
}

.section-subtitle {
  color: #4b5563;
  font-size: 1.2rem;
  max-width: 600px;
  margin: 0 auto;
  font-family: 'Railway', sans-serif;
}

/* -------------- Solutions Section ---------------- */
.solutions-section {
  padding: 50px 0;
  background: #fff;
}

.solutions-grid {
  display: grid;
  gap: 2rem;
  grid-template-columns: repeat(1, 1fr);
}


@media (min-width: 768px) {
  .solutions-grid {
    grid-template-columns: repeat(3, 1fr);
  }
}

.solution-card {
  background: white;
  border: 1px solid rgba(255, 74, 0, 0.1);
  border-radius: 16px;
  padding: 2rem;
  transition: all 0.3s ease;
}

.solution-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 20px rgba(255, 74, 0, 0.1);
}

.solution-icon {
  width: 3rem;
  height: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(255, 74, 0, 0.1);
  border-radius: 50%;
  margin-bottom: 1.5rem;
  color: #ff4d00;
}

.solution-title {
  color: #000;
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 1rem;
}

.solution-text {
  color: #4b5563;
  font-size: 16px;
  line-height: 1.6;
  margin-bottom: 1.5rem;
}

.solution-image {
  width: 100%;
  border-radius: 8px;
  overflow: hidden;
}

.solution-image img {
  width: 100%;
  height: auto;
  object-fit: cover;
}

.testimonials-section .landing-title,
.testimonials-section .landing-title h3 {
  color: #000;
  text-align: center;
  font-size: 32px;
  font-weight: 700;
  line-height: 40px;
  margin-bottom: 20px;
}

@media (min-width: 768px) {
  .testimonials-section .landing-title,
  .testimonials-section .landing-title h3 {
    font-size: 40px;
    font-weight: 700;
    line-height: 48px;
  }
}

.offer-section {
  padding: 50px 0;
  background: linear-gradient(to right, #fff5f2, #f5f9ff);
}

.offer-section .bottom-card {
  height: 100%;
  text-align: center;
  border: 0px;
  border-radius: 32px;
 
}

.offer-section .bCard-body {
  padding: 35px 40px;
}

@media (min-width: 768px) {
  .offer-section .bCard-body {
    padding: 60px 40px;
  }
}

.offer-section .demo-text {
  color: #000;
  font-size: 20px;
  font-weight: 500;
  line-height: 28px;
  margin-bottom: 20px;
}

.offer-section .emoji-icon {
  color: #ff4d00;
  font-size: 20px;
  margin-right: 10px;
}

.offer-section .list-item {
  display: flex;
  align-items: center;
  padding: 15px;
  margin-bottom: 15px;
  background: rgba(255, 255, 255, 0.5);
  border-radius: 12px;
  transition: transform 0.3s ease;
}

.offer-section .list-item:hover {
  transform: translateY(-2px);
}

.offer-section .card-text {
  color: #000;
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
}

@media (min-width: 768px) {
  .offer-section .card-text {
    font-size: 20px;
    line-height: 28px;
  }
}

.offer-section .leading-mark {
  display: inline-block;
  color: #ff4a00;
  font-size: 16px;  /* Increased from 14px */
  font-weight: 900;
  line-height: 24px;  /* Increased from 20px */
  letter-spacing: 1px;
  padding: 5px 15px;
  margin-bottom: 20px;
  text-transform: uppercase;
  border-radius: 40px;
  border: 1px solid rgba(255, 74, 0, 0.3);
  background: rgba(255, 74, 0, 0.1);
}

.offer-section .leading-mark.why-choose {
  font-size: 18px;  
  font-weight: 900;  
  line-height: 26px;
}

.offer-section .bullet-icon {
  color: #ff4d00;
  font-size: 24px;
  margin-right: 10px;
  line-height: 1;
  font-weight: bold;
}

.testimonials-section .leading-mark.impact-mark {
  display: inline-block;
  color: #ff4a00;
  font-size: 12px;
  font-weight: 900;
  line-height: 20px;
  letter-spacing: 1px;
  padding: 5px 15px;
  margin-bottom: 20px;
  text-transform: uppercase;
  border-radius: 40px;
  border: 1px solid rgba(255, 74, 0, 0.3);
  background: rgba(255, 74, 0, 0.1);
  margin-left: auto;
  margin-right: auto;
}

.testimonials-section .text-center {
  text-align: center;
  width: 100%;
}

.feature-image-wrapper {
  text-align: center;
  margin-bottom: 1.5rem;
  height: 200px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.feature-image-wrapper img {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
  border-radius: 8px;
}

/* Adjust image size for wide cards */
.col-lg-8 .feature-image-wrapper {
  height: 250px;
}

@media (max-width: 768px) {
  .feature-image-wrapper {
    height: 180px;
  }
  
  .col-lg-8 .feature-image-wrapper {
    height: 200px;
  }
}

.why-choose-section {
  padding: 50px 0;
  background: linear-gradient(to right, #fff5f2, #f5f9ff);
}

.why-choose-card {
  text-align: center;
  border: 0;
  border-radius: 32px;

  border: 1px solid rgba(255, 74, 0, 0.2);
}

.why-choose-body {
  padding: 38px 40px;
}

@media (min-width: 768px) {
  .why-choose-body {
    padding: 38px 40px;
  }
}

.why-choose-header {
  margin-bottom: 40px;
}

.why-choose-label {
  display: inline-block;
  color: #ff4a00;
  font-size: 14px;
  font-weight: 900;
  line-height: 20px;
  letter-spacing: 1px;
  padding: 5px 15px;
  margin-bottom: 20px;
  text-transform: uppercase;
  border-radius: 40px;
  border: 1px solid rgba(255, 74, 0, 0.3);
  background: rgba(255, 74, 0, 0.1);
}

.why-choose-title {
  font-family: "clashDisplay", sans-serif;
  font-weight: 600;
  font-size: 2.5rem;
  line-height: 1.2;
  color: #000;
  margin-bottom: 1rem;
}

.why-choose-subtitle {
  color: black;
  font-weight: 400;
  font-size: 18px;
  line-height: 1.5;
  margin-bottom: 0;
}

.why-choose-list {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 40px;
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
}

.why-choose-item {
  display: flex;
  align-items: center;
  gap: 12px;
  font-size: 18px;
  color: black;
  text-align: left;
}

.why-choose-check {
  color: #ff4d00;
  flex-shrink: 0;
}

.why-choose-cta {
  text-align: center;
  padding-top: 40px;
  border-top: 1px solid rgba(255, 74, 0, 0.1);
}

.why-choose-cta-content {
  margin-bottom: 24px;
}

.why-choose-cta-title {
  color: #000;
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 8px;
}

.why-choose-cta-text {
  color: #4b5563;
  font-size: 16px;
  margin-bottom: 0;
}

.why-choose-cta-button {
  display: inline-block;
  background: #ff4d00;
  color: white;
  padding: 12px 24px;
  border-radius: 8px;
  font-weight: 600;
  transition: all 0.2s ease;
}

.why-choose-cta-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(255, 77, 0, 0.2);
}


.problems-section {
  padding: 4rem 0;
  background: #0A2540;
}

.problems-section .section-title {
  color: #ff4a00;
  font-size: 2.75rem;
  font-weight: 700;
  text-align: center;
  margin-bottom: 1.5rem;
  letter-spacing: -0.02em;
}

.problems-section .section-subtitle {
  color: white;
  font-size: 1.25rem;
  text-align: center;
  margin: 0 auto 3rem;
  line-height: 1.6;
  padding: 0 2rem; 
  max-width: 800px;
}

.problems-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1.5rem 0rem;
}

.problem-card {
  background: #fff;
  border-radius: 12px;
  padding: 1.5rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
  width: 90%;
  margin: 0 auto;
  position: relative;
  padding-left: 10rem; /* Reduced from 12rem */
  display: flex;
  align-items: center;
}

/* First, define the floating animation */
@keyframes floating {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0px);
  }
}

/* Update the SVG container styles */
.problem-card::before {
  content: '';
  position: absolute;
  left: 2rem;
  top: 37%; /* Center vertically */
  transform: translateY(-50%); /* Ensure perfect vertical centering */
  width: 6rem;
  height: 6rem;
  border-radius: 50%;
  background-color: rgba(255, 74, 0, 0.1);
  background-size: 48px;
  background-repeat: no-repeat;
  background-position: center;
  animation: floating 3s ease-in-out infinite;
}

/* Add hover effect to speed up animation */
.problem-card:hover::before {
  animation: floating 2s ease-in-out infinite; /* Faster animation on hover */
}

/* Update individual SVG styles with new background positioning */
.problem-card:nth-child(1)::before {
  animation-delay: 0s; /* No delay for first card */
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='%23FF4A00' width='24' height='24'%3E%3Cpath d='M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zm.5-13H11v6l5.25 3.15.75-1.23-4.5-2.67z'/%3E%3C/svg%3E");
}

.problem-card:nth-child(2)::before {
  animation-delay: 0.5s; /* Slight delay for second card */
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='%23FF4A00' width='24' height='24'%3E%3Cpath d='M19 3H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 16H5V5h14v14zm-7-2h2V7h-4v2h2z'/%3E%3C/svg%3E");
}

.problem-card:nth-child(3)::before {
  animation-delay: 1s; /* More delay for third card */
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='%23FF4A00' width='24' height='24'%3E%3Cpath d='M20 2H4c-1.1 0-1.99.9-1.99 2L2 22l4-4h14c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2zm-7 12h-2v-2h2v2zm0-4h-2V6h2v4z'/%3E%3C/svg%3E");
}

.problem-card:nth-child(4)::before {
  animation-delay: 1.5s; /* Most delay for fourth card */
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='%23FF4A00' width='24' height='24'%3E%3Cpath d='M19 3H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 16H5V5h14v14zM7 10h2v7H7zm4-3h2v10h-2zm4 6h2v4h-2z'/%3E%3C/svg%3E");
}

/* Add a subtle glow effect on hover */
.problem-card:hover::before {
  box-shadow: 0 0 15px rgba(255, 74, 0, 0.3);
  transition: box-shadow 0.3s ease;
}

/* Ensure the vertical line doesn't move with the animation */
.problem-card::after {
  content: '';
  position: absolute;
  left: 8.5rem;
  top: 20%;
  height: 60%;
  width: 1px;
  background: rgba(255, 74, 0, 0.2);
  margin: 0 0.75rem;
  animation: none; /* Explicitly prevent animation on the divider */
}

.problem-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 20px 40px rgba(255, 74, 0, 0.08);
  border-color: rgba(255, 74, 0, 0.2);
}

.problem-title {
  font-size: 1.25rem;  /* Reduced from likely 1.5rem */
  margin-bottom: 1rem;  /* Reduced spacing */
  font-weight: 600;
  margin-left: 10px;
  color: #2d3748;
}

.problem-stats {
  display: grid;
  gap: 0.75rem;  /* Reduced from likely 1rem or more */
  margin-bottom: 1rem;
}

.stat-item {
  display: flex;
  align-items: center;
  gap: 0.75rem;  /* Reduced spacing */
  padding: 0.5rem;  /* Reduced padding */
  border-radius: 8px;
  transition: background-color 0.2s ease;
}

.stat-icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  color: #666;
}

.stat-icon svg {
  width: 20px;
  height: 20px;
}

.stat-text {
  font-size: 1.1rem;  /* Reduced from likely 1rem */
  color: #4a5568;
  line-height: 1.4;
}

.summary-box {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 0.75rem;  /* Reduced gap */
  margin-top: 1rem;
  padding-top: 0.75rem;
  border-top: 1px solid #e2e8f0;
}

.summary-item {
  text-align: center;
}

.summary-label {
  font-size: 0.75rem; 
  color: #718096;
  margin-bottom: 0.25rem;
}

.summary-value {
  font-size: 1rem;  
  font-weight: 600;
  color: #2d3748;
}



.stat-icon-clock svg {
  color: #FF6B6B;
}

.stat-icon-update svg {
  color: #4ECDC4;
}

.stat-icon-calendar svg {
  color: #45B7D1;
}

.stat-icon-edit svg {
  color: #96CEB4;
}

.stat-icon-timer svg {
  color: #FF8C42;
}

.stat-icon-money svg {
  color: #4CAF50;
}

.stat-icon-feedback svg {
  color: #9C27B0;
}

.stat-icon-chart svg {
  color: #2196F3;
}

.stat-icon-question svg {
  color: #FF5722;
}

.stat-highlight {
  color: #ff4d00;
  font-size: 1.2rem;
  font-weight: 700;
  margin: 0 0.2rem;
}

@media (max-width: 768px) {
  .problems-grid {
    grid-template-columns: 1fr;
    gap: 24px;
  }

  .problems-section .section-title {
    font-size: 28px;
    line-height: 1.3;
  }

  .problems-section .section-subtitle {
    font-size: 16px;
    line-height: 1.5;
  }

  .problem-card {
    padding: 24px;
  }

  .problem-card::before,
  .problem-card::after {
    display: none;
  }

  .problem-title {
    font-size: 20px;
  }

  .stat-item {
    font-size: 1rem;
    padding: 0.6rem 0.875rem;
  }
  
  .stat-icon {
    font-size: 1.2rem;
    margin-right: 0.75rem;
}

.summary-box {
    padding: 1.25rem;
  }
  
  .summary-value {
    font-size: 1.2rem;
  }
}

/* FAQ Section Styles */
.faq-section {
  text-align: center;
  padding: 60px 0;
}

.faq-section .faq-cardMain {
  margin-bottom: 3rem;
}



/* ---------------- For How AI Works page ---------------- */

/* Chanakya AI Works Page Styles */
.ai-works-page {
  background-color: #fff;
}

.ai-works-main {
  padding-top: 60px;
}

/* Banner Section */
.ai-works-banner-section {
  padding: 50px 0;
}

.ai-works-banner-wrapper {
  display: grid;
  gap: 3rem;
  align-items: center;
  margin-bottom: 3rem;
}

@media (min-width: 768px) {
  .ai-works-banner-wrapper {
    grid-template-columns: repeat(2, 1fr);
    padding-top: 50px;
  }
}

.ai-works-banner-title {
  color: #000;
  font-size: 2.5rem;
  line-height: 1.2;
  font-weight: 700;
  margin-bottom: 1rem;
  font-family: "clash-display", sans-serif;
  letter-spacing: -0.02em;
}

@media (min-width: 992px) {
  .ai-works-banner-title {
    font-size: 3.75rem;
  }
}

.ai-works-orange-text {
  color: #ff4d00;
  display: inline-block;
}

.ai-works-caveat-text {
  font-weight: 700;
}

.ai-works-banner-text {
  color: rgb(75, 85, 99);
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  margin-bottom: 1.5rem;
}

@media (min-width: 768px) {
  .ai-works-banner-text {
    font-size: 18px;
    line-height: 28px;
  }
}

.ai-works-btn-box {
  margin-bottom: 2rem;
}

.ai-works-btn-wrapper {
  display: inline-block;
}

.ai-works-custom-btn {
  display: inline-block;
  padding: 12px 24px;
  background: #ff4d00;
  color: white;
  font-weight: 600;
  font-size: 16px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.ai-works-custom-btn:hover {
  background: #e64500;
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(255, 77, 0, 0.2);
}

.ai-works-banner-right .ai-works-banner-card {
  padding: 2rem;
  border-radius: 16px;
  box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
    0 8px 10px -6px rgba(0, 0, 0, 0.1);
  background-color: white;
}

.ai-works-card-title {
  color: #000;
  font-size: 24px;
  line-height: 32px;
  font-weight: 700;
  margin-bottom: 1.25rem;
}

.ai-works-card-list {
  padding-left: 0;
  list-style: none;
  margin: 0;
}

.ai-works-card-list-item {
  display: flex;
  align-items: center;
  color: rgb(75, 85, 99);
  gap: 0.75rem;
  font-size: 16px;
  line-height: 28px;
  font-weight: 400;
  margin-bottom: 0.75rem;
}

.ai-works-card-list-item:last-child {
  margin-bottom: 0;
}

/* Icon styles */
.ai-works-check-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20' fill='%23ff4d00'%3E%3Cpath fill-rule='evenodd' d='M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z' clip-rule='evenodd'/%3E%3C/svg%3E");
  background-size: contain;
  background-repeat: no-repeat;
  flex-shrink: 0;
}

.ai-works-robot-icon {
  display: inline-block;
  width: 24px;
  height: 24px;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='%23ff4d00'%3E%3Cpath d='M13 4.055A9 9 0 0121 13v9H3v-9a9 9 0 018-8.945V1h2v3.055zM19 20v-7a7 7 0 00-14 0v7h14zm-7-2a1.5 1.5 0 110-3 1.5 1.5 0 010 3zm-3-3.5A3.5 3.5 0 0112 11a3.5 3.5 0 013.5 3.5h-7z'/%3E%3C/svg%3E");
  background-size: contain;
  background-repeat: no-repeat;
  margin-right: 10px;
}

.ai-works-teacher-icon {
  display: inline-block;
  width: 24px;
  height: 24px;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='%23ff4d00'%3E%3Cpath d='M20 17a1 1 0 01-1 1H5a1 1 0 01-1-1V7a1 1 0 011-1h14a1 1 0 011 1v10zm-2-9H6v7h12V8zm-8 2a1 1 0 110 2 1 1 0 010-2zm4 0a1 1 0 110 2 1 1 0 010-2z'/%3E%3C/svg%3E");
  background-size: contain;
  background-repeat: no-repeat;
  margin-right: 10px;
}

.ai-works-chart-icon {
  display: inline-block;
  width: 24px;
  height: 24px;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='%23ff4d00'%3E%3Cpath d='M3 12h4v9H3v-9zm7-4h4v13h-4V8zm7-6h4v19h-4V2z'/%3E%3C/svg%3E");
  background-size: contain;
  background-repeat: no-repeat;
  margin-right: 10px;
}

.ai-works-users-icon {
  display: inline-block;
  width: 24px;
  height: 24px;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='%23ff4d00'%3E%3Cpath d='M12 11a4 4 0 110-8 4 4 0 010 8zm0-2a2 2 0 100-4 2 2 0 000 4zm-1 2h2a5 5 0 015 5v2H6v-2a5 5 0 015-5z'/%3E%3C/svg%3E");
  background-size: contain;
  background-repeat: no-repeat;
  margin-right: 10px;
}

.ai-works-audience-check {
  display: inline-block;
  width: 20px;
  height: 20px;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20' fill='%23ff4d00'%3E%3Cpath fill-rule='evenodd' d='M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z' clip-rule='evenodd'/%3E%3C/svg%3E");
  background-size: contain;
  background-repeat: no-repeat;
  flex-shrink: 0;
}

/* Demo Section */
.ai-works-demo-section {
  padding: 50px 0;
  background: linear-gradient(to right, #fff5f2, #f5f9ff);
}

.ai-works-section-header {
  text-align: center;
  margin-bottom: 3rem;
}

.ai-works-leading-mark {
  display: inline-block;
  color: #ff4a00;
  font-size: 12px;
  font-weight: 900;
  line-height: 20px;
  letter-spacing: 1px;
  padding: 5px 15px;
  margin-bottom: 20px;
  text-transform: uppercase;
  border-radius: 40px;
  border: 1px solid rgba(255, 74, 0, 0.3);
  background: rgba(255, 74, 0, 0.1);
}

.ai-works-section-title {
  color: #000;
  font-size: 32px;
  font-weight: 700;
  line-height: 1.2;
  margin-bottom: 1rem;
}

@media (min-width: 768px) {
  .ai-works-section-title {
    font-size: 40px;
  }
}

.ai-works-section-subtitle {
  color: #4b5563;
  font-size: 16px;
  line-height: 1.5;
  max-width: 600px;
  margin: 0 auto;
}

@media (min-width: 768px) {
  .ai-works-section-subtitle {
    font-size: 18px;
  }
}


.ai-works-macbook-showcase {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem 0;
}

.ai-works-macbook-frame {
  position: relative;
  width: 100%;
  max-width: 1200px;
  /* background: linear-gradient(135deg, #2c2c2c, #1a1a1a); */
  border-radius: 20px;
  /* box-shadow: 0 50px 100px rgba(0, 0, 0, 0.4), 0 15px 50px rgba(0, 0, 0, 0.3); */
  overflow: hidden;
  transform: perspective(2000px) rotateX(4deg);
  transition: all 0.3sease;
}

.ai-works-macbook-frame:hover {
  transform: perspective(2000px) rotateX(3deg) scale(1.01);
}

.ai-works-macbook-lid {
  /* background: linear-gradient(to bottom, #1e1e1e, #121212);
  border-top-left-radius: 20px;
  border-top-right-radius: 20px; */
  padding: 15px;
}

.ai-works-macbook-display {
  position: relative;
  background: #0a0a0a;
  border-radius: 15px;
  overflow: hidden;
}



.ai-works-macbook-camera {
  position: absolute;
  top: 10px;
  left: 50%;
  transform: translateX(-50%);
  width: 8px;
  height: 8px;
  background: #444;
  border-radius: 50%;
  z-index: 10;
}

.ai-works-macbook-screen {
  position: relative;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
}

.ai-works-macbook-keyboard {
  height: 28px;
  background: linear-gradient(to bottom, #1a1a1a, #121212);
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ai-works-macbook-touchpad {
  width: 100px;
  height: 8px;
  background: #2c2c2c;
  border-radius: 10px;
}

/* How It Works Section */
.ai-works-how-it-works {
  padding: 50px 0;
}

.ai-works-card-main {
  margin-top: 3rem;
}

.ai-works-cards-wrapper {
  position: relative;
  display: flex;
  justify-content: space-between;
  column-gap: 50px;
  padding-bottom: 3rem;
  margin-bottom: 2rem;
}

@media (max-width: 768px) {
  .ai-works-cards-wrapper {
    flex-direction: column;
    row-gap: 30px;
  }
}

.ai-works-cards-wrapper:nth-child(even) {
  flex-direction: row-reverse;
}

@media (max-width: 768px) {
  .ai-works-cards-wrapper:nth-child(even) {
    flex-direction: column;
  }
}

.ai-works-wrapper-step {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ff4d00;
  color: white;
  font-weight: 700;
  border-radius: 50%;
  z-index: 10;
}

.ai-works-content-wrapper {
  flex: 1;
}

.ai-works-work-title {
  display: flex;
  align-items: center;
  color: #000;
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 1rem;
}

.ai-works-card-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  background-color: rgba(255, 77, 0, 0.1);
  border-radius: 50%;
  margin-right: 12px;
}

.ai-works-work-text {
  color: #4b5563;
  font-size: 16px;
  line-height: 1.6;
}

.ai-works-img-main {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ai-works-img-main img {
  max-width: 100%;
  border-radius: 8px;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
}

/* Target Audience Section */
.ai-works-target-audience {
  padding: 50px 0;
  background: linear-gradient(to right, #fff5f2, #f5f9ff);
}

.ai-works-audience-grid {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 2rem;
}

@media (min-width: 768px) {
  .ai-works-audience-grid {
    grid-template-columns: repeat(3, 1fr);
  }
}

.ai-works-audience-card {
  background: white;
  border: 1px solid rgba(255, 74, 0, 0.1);
  border-radius: 16px;
  padding: 2rem;
  transition: all 0.3s ease;
}

.ai-works-audience-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 20px rgba(255, 74, 0, 0.1);
}

.ai-works-audience-title {
  display: flex;
  align-items: center;
  gap: 1rem;
  color: #000;
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 1rem;
}

.ai-works-audience-icon {
  width: 2.5rem;
  height: 2.5rem;
  padding: 0.5rem;
  border-radius: 50%;
  background: rgba(255, 74, 0, 0.1);
  color: #ff4d00;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ai-works-audience-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.ai-works-audience-item {
  display: flex;
  align-items: center;
  gap: 0.75rem;
  color: #4b5563;
  font-size: 16px;
  line-height: 1.6;
  margin-bottom: 0.5rem;
}

/* Power Up Section */
.ai-works-powerUp-section {
  padding: 50px 0;
  text-align: center;
  background: linear-gradient(to right, #fff5f2, #f5f9ff);
}

.ai-works-tag-main {
  margin-bottom: 1.5rem;
}

.ai-works-section-tag {
  display: inline-block;
  color: #ff4a00;
  font-size: 14px;
  font-weight: 700;
  padding: 8px 16px;
  border-radius: 40px;
  background: rgba(255, 74, 0, 0.1);
  border: 1px solid rgba(255, 74, 0, 0.3);
}

.ai-works-powerUp-text {
  color: #000;
  font-size: 28px;
  font-weight: 700;
  line-height: 1.4;
  max-width: 800px;
  margin: 0 auto 2rem;
}

@media (min-width: 768px) {
  .ai-works-powerUp-text {
    font-size: 32px;
  }
}

.ai-works-accessBox-main {
  margin-top: 2rem;
}

.ai-works-access-text {
  display: inline-block;
  padding: 14px 28px;
  background: #ff4d00;
  color: white;
  font-size: 18px;
  font-weight: 600;
  border-radius: 8px;
  text-decoration: none;
  transition: all 0.3s ease;
}

.ai-works-access-text:hover {
  background: #e64500;
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(255, 77, 0, 0.2);
}

/* OCR Demo Styles */
.ai-works-ocr-demo {
  margin-bottom: 4rem;
}

.ai-works-ocr-title {
  color: #000;
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 1rem;
  text-align: center;
}

.ai-works-ocr-description {
  color: #4b5563;
  font-size: 16px;
  line-height: 1.6;
  text-align: center;
  margin-bottom: 2rem;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
}

.ai-works-ocr-comparison {
  max-width: 900px;
  margin: 0 auto;
}

.ai-works-ocr-slider-container {
  background: white;
  border-radius: 12px;
  overflow: hidden;

}

.ai-works-ocr-slider-wrapper {
  position: relative;
  width: 100%;
  height: 500px;
  overflow: hidden;
  background: #f8f9fa;
}

.ai-works-ocr-document {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  z-index: 1;
  overflow: hidden;
}

.ai-works-ocr-result {
  position: absolute;
  top: 0;
  height: 100%;
  z-index: 2;
  background: white;
  overflow: hidden;
}

.ai-works-ocr-image,
.ai-works-ocr-canvas {
  width: 100%;
  height: 100%;
  object-fit: contain;
  display: block;
}

.ai-works-ocr-slider {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 4px;
  background: #ff4d00;
  z-index: 5;
  transform: translateX(-50%);
  cursor: ew-resize;
}

.ai-works-ocr-slider-handle {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 40px;
  height: 40px;
  background: #ff4d00;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-weight: bold;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  transform: translate(-50%, -50%);
  z-index: 10;
  cursor: ew-resize;
}

.ai-works-ocr-slider-arrow {
  font-size: 18px;
}

.ai-works-ocr-labels {
  display: flex;
  justify-content: space-between;
  padding: 15px 20px;
  background: #f8f9fa;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
}

.ai-works-ocr-label {
  font-size: 16px;
  font-weight: 600;
  color: #4b5563;
  transition: color 0.3s ease;
}

.ai-works-ocr-label.active {
  color: #ff4d00;
}

.ai-works-math-content {
  background: white;
  padding: 30px;
  width: 100%;
  max-width: 800px;
  font-size: 16px;
  line-height: 1.8;
}

.ai-works-example-tabs {
  display: flex;
  margin-bottom: 20px;
  border-bottom: 1px solid rgba(255, 74, 0, 0.2);
}

.ai-works-example-tab {
  padding: 10px 20px;
  cursor: pointer;
  font-weight: 500;
  color: #4b5563;
  border-bottom: 2px solid transparent;
  transition: all 0.3s ease;
}

.ai-works-example-tab.active {
  color: #ff4d00;
  border-bottom: 2px solid #ff4d00;
}

.ai-works-ocr-slider-container {
  margin-bottom: 30px;
}

.ai-works-ocr-demo {
  margin-bottom: 4rem;
}

.ai-works-ocr-title {
  color: #000;
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 1rem;
  text-align: center;
}

.ai-works-ocr-description {
  color: #4b5563;
  font-size: 16px;
  line-height: 1.6;
  text-align: center;
  margin-bottom: 2rem;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
}

.ai-works-examples-sequence {
  display: flex;
  flex-direction: column;
  gap: 3rem;
  margin-bottom: 2rem;
}

.ai-works-example-item {
  background: white;
  border-radius: 12px;
  padding: 1.5rem;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.1);
}

.ai-works-example-header {
  display: flex;
  align-items: center;
  margin-bottom: 1.5rem;
}

.ai-works-example-number {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  background: #ff4d00;
  color: white;
  font-weight: 700;
  border-radius: 50%;
  margin-right: 1rem;
}

.ai-works-example-name {
  color: #000;
  font-size: 18px;
  font-weight: 700;
}

.ai-works-ocr-comparison {
  max-width: 900px;
  margin: 0 auto;
}

.ai-works-ocr-slider-container {
  background: white;
  border-radius: 12px;
  overflow: hidden;
  margin-bottom: 1rem;
}

.ai-works-example-description {
  color: #4b5563;
  font-size: 16px;
  line-height: 1.6;
  margin-top: 1rem;
}

.ai-works-ocr-slider-wrapper {
  position: relative;
  width: 100%;
  height: 500px;
  overflow: hidden;
  background: #f8f9fa;
}

.ai-works-ocr-document {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  z-index: 1;
  overflow: hidden;
}

.ai-works-ocr-result {
  position: absolute;
  top: 0;
  height: 100%;
  z-index: 2;
  background: white;
  overflow: hidden;
}

.ai-works-ocr-image,
.ai-works-ocr-canvas {
  width: 100%;
  height: 100%;
  object-fit: contain;
  display: block;
}

.ai-works-ocr-slider {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 4px;
  background: #ff4d00;
  z-index: 5;
  transform: translateX(-50%);
  cursor: ew-resize;
}

.ai-works-ocr-slider-handle {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 40px;
  height: 40px;
  background: #ff4d00;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-weight: bold;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  transform: translate(-50%, -50%);
  z-index: 10;
  cursor: ew-resize;
}

.ai-works-ocr-slider-arrow {
  font-size: 18px;
}

.ai-works-ocr-labels {
  display: flex;
  justify-content: space-between;
  padding: 15px 20px;
  background: #f8f9fa;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
}

.ai-works-ocr-label {
  font-size: 16px;
  font-weight: 600;
  color: #4b5563;
  transition: color 0.3s ease;
}

.ai-works-ocr-label.active {
  color: #ff4d00;
}

.ai-works-math-content {
  background: white;
  padding: 30px;
  width: 100%;
  max-width: 800px;
  font-size: 16px;
  line-height: 1.8;
}

.ai-works-ocr-demo {
  margin-bottom: 4rem;
}

.ai-works-ocr-title {
  color: #000;
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 1rem;
  text-align: center;
}

.ai-works-ocr-description {
  color: #4b5563;
  font-size: 16px;
  line-height: 1.6;
  text-align: center;
  margin-bottom: 2rem;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
}

.ai-works-example-tabs {
  display: none;
}

.ai-works-examples-sequence {
  display: flex;
  flex-direction: column;
  gap: 5rem;
  margin-bottom: 2rem;
}

.ai-works-example-item {
  background: white;
  border-radius: 12px;
  padding: 1.5rem;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.1);
}

.ai-works-example-header {
  display: flex;
  align-items: center;
  margin-bottom: 1.5rem;
}

.ai-works-example-number {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  background: #ff4d00;
  color: white;
  font-weight: 700;
  border-radius: 50%;
  margin-right: 1rem;
}

.ai-works-example-name {
  color: #000;
  font-size: 18px;
  font-weight: 700;
}

.ai-works-example-description {
  color: #4b5563;
  font-size: 16px;
  line-height: 1.6;
  margin-top: 1rem;
}

.ai-works-ocr-slider-wrapper {
  position: relative;
  width: 100%;
  height: 400px; 
  overflow: hidden;
  background: #f8f9fa;
}

.ai-works-examples-sequence {
  display: flex;
  flex-direction: column;
  gap: 3rem;
  margin-bottom: 2rem;
  max-width: 800px; 
  margin-left: auto;
  margin-right: auto;
}

.ai-works-example-item {
  background: white;
  border-radius: 12px;
  padding: 1.25rem;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.08); 
}


@media (max-width: 768px) {
  .ai-works-ocr-slider-wrapper {
    height: 300px; 
  }
  
  .ai-works-examples-sequence {
    gap: 2rem;
  }
}


.ai-works-examples-sequence {
  display: flex;
  flex-direction: column;
  gap: 3rem;
  margin-bottom: 2rem;
  max-width: 900px;
  margin-left: auto;
  margin-right: auto;
}

.ai-works-example-item {
  background: white;
  border-radius: 12px;
  padding: 1.25rem;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.08);
}


.ai-works-example-item:nth-child(odd) {
  margin-right: auto;
  margin-left: 0;
  width: 90%;
}

.ai-works-example-item:nth-child(even) {
  margin-left: 300px;
  margin-right: 0;
  width: 90%;
}


@media (max-width: 768px) {
  .ai-works-ocr-slider-wrapper {
    height: 300px;
  }
  
  .ai-works-examples-sequence {
    gap: 2rem;
  }
  
  .ai-works-example-item:nth-child(odd),
  .ai-works-example-item:nth-child(even) {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }
}


.impact-section {
  padding: 4rem 0;
  background-color: var(--bg-light, #f8f9fa);
}

.impact-card {
  border: none;
  border-radius: 16px;
  overflow: hidden;
  background: #fff;

}
.leading-mark .impact-mark h5 {
display: inline-block;
color: #ff4a00;
font-size: 14px;
font-weight: 900;
line-height: 20px;
letter-spacing: 1px;
padding: 5px 15px;
margin-bottom: 20px;
text-transform: uppercase;
border-radius: 40px;
border: 1px solid rgba(255, 74, 0, 0.3);
background: rgba(255, 74, 0, 0.1);
}
.impact-body {
  padding: 3rem;
}

.impact-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 2rem;
  margin-top: 3rem;
}

.impact-item {
  padding: 2rem;
  background: #fff;
  border-radius: 12px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  border: 1px solid rgba(0, 0, 0, 0.08);
}

.impact-item:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.12);
  border-color: #FF6B00;
}

.impact-icon {
  width: 64px;
  height: 64px;
  background: rgba(255, 107, 0, 0.1); 
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1.5rem;
  color: #FF6B00; 
}

.impact-title {
  font-size: 1.5rem;
  font-weight: 600;
  color: #1a1a1a;
  margin-bottom: 1rem;
}

.impact-text {
  color: #4a5568;
  line-height: 1.6;
  margin-bottom: 1.5rem;
  font-size: 1rem;
}

.impact-metric {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
  background: rgba(255, 107, 0, 0.05); /* Very light orange background */
  border-radius: 8px;
  border: 1px solid rgba(255, 107, 0, 0.1);
}

.metric-value {
  font-size: 2rem;
  font-weight: 700;
  color: #FF6B00; /* Your orange theme color */
}

.metric-label {
  font-size: 0.875rem;
  color: #4a5568;
  margin-top: 0.25rem;
  font-weight: 500;
}

@media (max-width: 768px) {
  .impact-body {
    padding: 1.5rem;
  }

  .impact-grid {
    grid-template-columns: 1fr;
    gap: 1.5rem;
  }

  .impact-item {
    padding: 1.5rem;
  }

  .impact-title {
    font-size: 1.25rem;
  }

  .metric-value {
    font-size: 1.75rem;
  }
}

.institutional-impact-section {
  padding: 4rem 0;
}


.institutional-impact-card {
  border: none;
  border-radius: 16px;
  overflow: hidden;
  background: #0A2540;

}
.institutional-impact-badge.leading-mark {
  display: inline-block;
  color: white !important;
  font-size: 14px;
  font-weight: 900;
  line-height: 20px;
  letter-spacing: 1px;
  padding: 5px 15px;
  margin-bottom: 20px;
  text-transform: uppercase;
  border-radius: 40px;
  border: none !important;
  background: none !important;
}

.institutional-impact-section .institutional-impact-badge.leading-mark {
  color: white !important;
  border: none !important;
  background: none !important;
}

.institutional-impact-badge {
  display: inline-block;
  color: white;
  font-size: 14px;
  font-weight: 900;
  line-height: 2px;
  letter-spacing: 1px;
  padding: 5px 15px;
  margin-bottom: 20px;
  text-transform: uppercase;
  border-radius: 40px;

}

.institutional-impact-content {
  padding: 3rem;
}

.institutional-impact-metrics-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 2rem;
  margin-top: 3rem;
}

.impact-metric-card {
  padding: 2rem;
  background: #fff;
  border-radius: 12px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  border: 1px solid rgba(0, 0, 0, 0.08);
  display: flex;
  flex-direction: column;
  justify-content: space-between; 
  height: 100%;
}

.impact-metric-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.12);
  border-color:rgba(255, 74, 0, 0.1);
}

.impact-metric-icon-wrapper {
  width: 64px;
  height: 64px;
  background: rgba(255, 107, 0, 0.1);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1.5rem;
  color: #ff4a00;
}

.impact-metric-title {
  font-size: 1.5rem;
  font-weight: 600;
  color: #1a1a1a;
  margin-bottom: 1rem;
}

.impact-metric-description {
  font-size: 1.125rem; 
  line-height: 1.6;
  color: black;
  margin: 1rem 0;
}

.impact-metric-stats {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
  background: rgba(255, 107, 0, 0.1) ;
  border-radius: 8px;
  border: 1px solid rgba(255, 107, 0, 0.1);
}

.impact-metric-value {
  font-size: 2rem;
  font-weight: 700;
  color: #ff4a00;
}

.impact-metric-label {
  font-size: 0.875rem;
  color: #4a5568;
  margin-top: 0.25rem;
  font-weight: 500;
}

.impact-metric-stats-row {
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 1rem;
  background: rgba(255, 74, 0, 0.1);
  border-radius: 8px;
  border: 1px solid rgba(255, 107, 0, 0.1);
  margin-top: auto; 
}

.impact-metric-value {
  font-size: 2rem;
  font-weight: 700;
  color: #ff4a00;
  white-space: nowrap; 
}

.impact-metric-label {
  font-size: 0.875rem;
  color: #4a5568;
  font-weight: 500;
  flex-grow: 1; 
}

.institutional-impact-subtitle {
  color: white;
  font-size: 1.3rem;
}

.institutional-impact-title  {
  color: #ff4a00;
  font-size: 2.5rem;
  font-weight: 700;
  margin-bottom: 1rem;
  
}

@media (max-width: 768px) {
  .impact-metric-stats-row {
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: 0.5rem;
  }
  
  .impact-metric-value {
    font-size: 1.75rem;
  }
  .impact-metric-description {
    font-size: 1rem; 
  }
}

.pilot-program-section {
  padding: 5rem 0;
  background-color: #f8fafc;
}

.pilot-program-card {
  border: none;
  border-radius: 1rem;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.08);
  background: linear-gradient(135deg, #ffffff 0%, #f8fafc 100%);
  overflow: hidden;
  transition: transform 0.3s ease;
}

.pilot-program-card:hover {
  transform: translateY(-5px);
}

.pilot-program-body {
  padding: 3rem;
}

.pilot-program-label {
  display: inline-block;
  padding: 0.5rem 1rem;
  background-color: #ff4a00; 
  color: white;
  border-radius: 2rem;
  font-weight: 600;
  font-size: 0.9rem;
  margin-bottom: 1.5rem;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.pilot-program-title {
  font-family: "clashDisplay", sans-serif;
  font-weight: 600;
  font-size: 2.1rem;
  line-height: 1.2;
  color: #000;
  margin-bottom: 1rem;
}

.pilot-program-subtitle {
  font-size: 1.2rem;
  color: #64748b; 
  margin-bottom: 3rem;
}

.pilot-benefits-list {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;
  margin-bottom: 3rem;
}

.pilot-benefit-item {
  display: flex;
  align-items: flex-start;
  gap: 1rem;
  padding: 1.5rem;
  background-color: white;
  border-radius: 1rem;
  transition: all 0.3s ease;
  border: 1px solid #e2e8f0; 
}

.pilot-benefit-item:hover {
  transform: translateY(-5px);
  border-color: #FF7A00; 
}

.benefit-icon-wrapper {
  padding: 1rem;
  background-color: rgba(255, 74, 0, 0.1); 
  border-radius: 1rem;
  color: #ff4a00; 
}

.benefit-title {
  font-size: 1.2rem;
  font-weight: 600;
  color: #1e293b; 
  margin-bottom: 0.5rem;
}

.benefit-text {
  font-size: 1rem;
  color: #64748b; 
  line-height: 1.5;
}

.pilot-program-cta {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: rgba(255, 74, 0, 0.1);
  padding: 2rem;
  border-radius: 1rem;
  margin-top: 2rem;
}

.pilot-program-cta-title {
  font-size: 1.5rem;
  font-weight: 600;
  color: black;
  margin-bottom: 0.5rem;
}

.pilot-program-cta-text {
  font-size: 1.1rem;
  color: black; 
}

.pilot-program-cta-button {
  padding: 1rem 2rem;
  background-color: #FF7A00; 
  color: white;
  border-radius: 2rem;
  font-weight: 600;
  text-decoration: none;
  transition: all 0.3s ease;
}

.pilot-program-cta-button:hover {
  background-color: #ff8c1a; 
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(255, 122, 0, 0.25);
}

@media (max-width: 768px) {
  .pilot-program-body {
    padding: 2rem;
  }

  .pilot-benefits-list {
    grid-template-columns: 1fr;
    gap: 1rem;
  }

  .pilot-program-cta {
    flex-direction: column;
    text-align: center;
    gap: 1.5rem;
  }

  .pilot-program-title {
    font-size: 2.1rem;
  }
}

.education-page-main .section-subtitle,
.pilot-program-subtitle,
.why-choose-subtitle,
.institutional-impact-subtitle {
  font-family: "jakarta-regular", sans-serif;
}

.problem-card .stat-text,
.feature-section .everything-text,
.impact-metric-description,
.pilot-benefit-item .benefit-text,
.why-choose-item span,
.powerUp-text,
.faq-section .faq-cardMain .card-text,
.ai-works-card-list-item,
.stat-item .stat-text,
.pilot-program-cta-text,
.why-choose-list span {
  font-family: "railway-regular", sans-serif;
}

.chat-page .landing-title {
  font-family: 'clashDisplay';
}

.chat-page .section-subtitle,
.chat-page .subs-subtitle,
.chat-page .leading-mark {
  font-family: 'Plus Jakarta Sans', sans-serif;
}

.chat-page .landing-text,
.chat-page .card-text,
.chat-page .everything-text,
.chat-page .keyPoints-text,
.chat-page .subs-text {
  font-family: 'Railway', sans-serif;
}


.highlight-text {
  color: #ff4d00;
  font-weight: 700;
  position: relative;
  display: inline-block;
}





.everything-card:hover .highlight-text {
  text-decoration: none;
  transform: translateY(-1px);
  transition: all 0.2s ease;
}

.feature-content {
  display: flex;
  flex-direction: column;
  padding: 0.5rem 0;
}

.feature-heading {
  margin-bottom: 1rem;
}

.feature-heading .text-heading {
  color: #000;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1.3;
  margin: 0;
  transition: color 0.3s ease;
}

.everything-card:hover .feature-heading .text-heading {
  color: #ff4d00;
}

.feature-text {
  color: #4b5563;
  font-size: 1.125rem;
  font-weight: 500;
  line-height: 1.6;
}


@media (max-width: 768px) {
  .feature-heading .text-heading {
    font-size: 1.25rem;
  }
  
  .feature-text {
    font-size: 1rem;
  }
}


.everything-you-do-sec .everything-card .everything-text {
  color: #000000;
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
}

@media (min-width: 992px) {
  .everything-you-do-sec .everything-card .everything-text {
    font-size: 20px;
    line-height: 28px;
  }
}

.everything-you-do-sec .everything-card .everything-text span {
  font-weight: 700;
}


/* Get in touch section */
.get-in-touch-section {
  padding: 60px 0;
  background-color: #f8f9fa;
}

.get-in-touch-card {
  background: #fff;
  border-radius: 16px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 4px 24px rgba(0, 0, 0, 0.05);
  transition: all 0.3s ease;
}

.get-in-touch-body {
  padding: 40px;
}

.get-in-touch-header {
  text-align: center;
  margin-bottom: 40px;
}

.get-in-touch-label {
  display: inline-block;
  padding: 8px 16px;
  background:rgba(255, 74, 0, 0.1);
  color:  #ff4a00;
  border-radius: 20px;
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 16px;
}

.get-in-touch-title {
  font-size: 32px;
  font-weight: 700;
  color: #1a1a1a;
  margin-bottom: 12px;
}

.get-in-touch-subtitle {
  color: #666;
  font-size: 16px;
  margin-bottom: 0;
}

.get-in-touch-form {
  max-width: 600px;
  margin: 0 auto;
}

.form-row {
  display: flex;
  gap: 20px;
  margin-bottom: 20px;
}

.form-group {
  flex: 1;
  margin-bottom: 20px;
}

.form-control {
  width: 100%;
  padding: 12px 16px;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  font-size: 15px;
  transition: all 0.3s ease;
}

.form-control:focus {
  outline: none;
  border-color: #ff6600;
  box-shadow: 0 0 0 2px rgba(255, 102, 0, 0.1);
}

textarea.form-control {
  resize: vertical;
  min-height: 100px;
}

.submit-button {
  width: 100%;
  padding: 14px 24px;
  background: #ff4d00;
  color: white;
  border: none;
  border-radius: 8px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
}

.submit-button:hover {
  background: #ff4d00;
  transform: translateY(-1px);
}

@media (max-width: 768px) {
  .get-in-touch-body {
    padding: 24px;
  }

  .form-row {
    flex-direction: column;
    gap: 0;
  }

  .get-in-touch-title {
    font-size: 24px;
  }
}



.ai-works-banner-title,
.ai-works-section-title,
.ai-works-card-title,
.ai-works-example-name,
.ai-works-audience-title {
  font-family: 'clashDisplay';
}

.ai-works-banner-text,
.ai-works-section-subtitle,
.ai-works-example-description,
.ai-works-audience-item,
.ai-works-powerUp-text,
.ai-works-section-tag,
.ai-works-access-text,
.ai-works-leading-mark {
  font-family: 'Railway', sans-serif;
}

.floating-call-button {
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: #ff4a00;
  border: none;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  transition: all 0.3s ease;
}

.floating-call-button svg {
  color: white;
  width: 30px;
  height: 30px;
}

.floating-call-button:hover {
  transform: scale(1.1);
  background-color: #ff4a00;
  box-shadow: 0 6px 16px rgba(0, 0, 0, 0.2);
}

@media (max-width: 768px) {
  .floating-call-button {
    bottom: 20px;
    right: 20px;
    width: 50px;
    height: 50px;
  }
  
  .floating-call-button svg {
    width: 24px;
    height: 24px;
  }
}

